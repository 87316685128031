import React, { useState } from 'react';
import { Edit, Share2 } from 'lucide-react';
import { motion } from 'framer-motion';
import ProjectEditDialog from '../ProjectEditDialog';
import ShareProjectDialog from '../ShareProjectDialog';

const ProjectCard = ({ project, onSelect, onEdit, onShare, onDelete, isSelected }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  
  const handleSelect = () => {
    onSelect(project.id);
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    onEdit(project);  // Pass the entire project object
  };

  return (
    <>
      <motion.div
        onClick={handleSelect}
        className={`group relative w-full flex flex-col p-3 rounded-xl transition-all h-[100px] cursor-pointer
          ${isSelected 
            ? 'bg-blue-500/20 shadow-lg ring-2 ring-blue-500/50' 
            : 'bg-gray-800/50 hover:bg-gray-800/80 hover:scale-[1.02] hover:-translate-y-0.5'
          }`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <div className="flex items-start justify-between">
          <div className="flex-1">
            <h3 className={`text-sm font-medium line-clamp-1 ${
              isSelected ? 'text-blue-200' : 'text-gray-200'
            }`}>
              {project.name}
            </h3>
            <p className={`text-xs line-clamp-2 mt-1 ${
              isSelected ? 'text-blue-300/80' : 'text-gray-400'
            }`}>
              {project.objective || 'No objective set'}
            </p>
          </div>
        </div>

        <div className="absolute top-1.5 right-2 flex gap-1">
          {onShare && (
            <button
              type="button"
              title="Share Project"
              onClick={(e) => {
                e.stopPropagation();
                setIsSharing(true);
              }}
              className={`p-1.5 opacity-0 group-hover:opacity-100 
                transition-opacity rounded-full shadow-sm hover:opacity-80
                ${isSelected ? 'bg-blue-600/50' : 'bg-gray-700'}`}
            >
              <Share2 className={`h-3.5 w-3.5 ${isSelected ? 'text-blue-200' : 'text-gray-300'}`} />
            </button>
          )}
          {onEdit && (
            <button
              type="button"
              title="Edit Project"
              onClick={handleEditClick}
              className={`p-1.5 opacity-0 group-hover:opacity-100 
                transition-opacity rounded-full shadow-sm hover:opacity-80
                ${isSelected ? 'bg-blue-600/50' : 'bg-gray-700'}`}
            >
              <Edit className={`h-3.5 w-3.5 ${isSelected ? 'text-blue-200' : 'text-gray-300'}`} />
            </button>
          )}
        </div>
      </motion.div>

      {isSharing && (
        <ShareProjectDialog
          onShare={async (email) => {
            await onShare(project, email);
            setIsSharing(false);
          }}
          onClose={() => setIsSharing(false)}
        />
      )}
    </>
  );
};

export default ProjectCard; 