import { useState, useEffect, useCallback } from 'react';
import useApi from './useApi';
import { handleHttpError } from '../utils/errorHandling';
import useDataSources from './useDataSources';

const useAgents = ({
  selectedProjectId,
  selectedThreadId, 
  activeAgents,
  setActiveAgents,
  fetchAgentsForThread
}) => {
  const { api } = useApi();
  const { fetchDataSources, addDataSource, removeDataSource } = useDataSources();

  const [availableAgents, setAvailableAgents] = useState([]);
  const [agentData, setAgentData] = useState({});
  const [isLoadingAgents, setIsLoading] = useState(false);
  const [models, setModels] = useState([]);

  const fetchModels = useCallback(async () => {
    try {
      const response = await api.get('/users/models');
      setModels(response.data.models);
      return response.data.models;
    } catch (error) {
      console.error('Error fetching models:', error);
      handleHttpError(error, 'fetching models');
      return [];
    }
  }, [api]);

  useEffect(() => {
    fetchModels();
  }, [fetchModels]);

  useEffect(() => {
    if (selectedThreadId) {
      refreshAgents();
    }
  }, [selectedThreadId]);

  const fetchAvailableAgents = useCallback(async () => {
    if (!selectedProjectId) return;

    try {
      const response = await api.get(`/agents/${selectedProjectId}`);
      setAvailableAgents(response.data.agents);
    } catch (error) {
      handleHttpError(error, 'fetching available agents');
    } 
  }, [api, selectedProjectId]);

  const refreshAgents = useCallback(async () => {
    try {
      setIsLoading(true);
      if (selectedThreadId) {
        await Promise.all([
          fetchAvailableAgents(),
          fetchAgentsForThread(selectedThreadId)
        ]);
      } else {
        await fetchAvailableAgents();
        setActiveAgents([]);
      }
    } catch (error) {
      handleHttpError(error, 'refreshing agents');
    } finally {
      setIsLoading(false);
    }
  }, [selectedThreadId]);

  useEffect(() => {
    refreshAgents();
  }, []);

  const addAgentToConversation = async (agentId) => {
    if (!selectedThreadId) {
      handleHttpError(new Error('No conversation selected'), 'adding agent');
    }

    try {
      if (!activeAgents.some(agent => agent.id === agentId)) {
        await api.post(`/conversations/${selectedProjectId}/${selectedThreadId}/agents/${agentId}`);
        const agent = availableAgents.find(agent => agent.id === agentId);
        if (agent) {
          setActiveAgents(prevAgents => [...prevAgents, agent]);
          await fetchDataSources(selectedProjectId, agent.id, 'agent');
       }
      }
    } catch (error) {
      handleHttpError(error, 'adding agent to conversation');
    } 
  };

  const removeAgentFromConversation = async (agentId) => {
    try {
      await api.delete(`/conversations/${selectedProjectId}/${selectedThreadId}/agents/${agentId}`);
      setActiveAgents(prevAgents => prevAgents.filter(agent => agent.id !== agentId));
    } catch (error) {
      handleHttpError(error, 'removing agent from conversation');
    }
  };

  const updateAgent = async (agentId, updateData) => {
    try {
      const response = await api.put(`/agents/${selectedProjectId}/${agentId}`, {
        agent_data: {
          name: updateData.name,
          version: {
            description: updateData.description,
            instructions: updateData.instructions,
            model: updateData.model
          }
        }
      });
      const updatedAgent = response.data.agent;
      
      setAvailableAgents(agents => agents.map(agent =>
        agent.id === updatedAgent.id ? updatedAgent : agent
      ));
      setActiveAgents(agents => agents.map(agent =>
        agent.id === updatedAgent.id ? updatedAgent : agent
      ));
      return updatedAgent;
    } catch (error) {
      handleHttpError(error, 'updating agent');
    }
  };

  const resetDefaultAgent = async (agentId) => {
    try {
      const response = await api.post(`/agents/${selectedProjectId}/${agentId}/reset`);
      const resetAgent = response.data.agent;
      setAvailableAgents(agents => agents.map(agent =>
        agent.id === resetAgent.id ? resetAgent : agent
      ));
      setActiveAgents(agents => agents.map(agent =>
        agent.id === resetAgent.id ? resetAgent : agent
      ));
      return resetAgent;
    } catch (error) {
      handleHttpError(error, 'resetting the agent');
    }
  };

  const createAgent = async (agentData) => {
    if (!selectedProjectId) return;
    if (agentData.name.trim() === '' || agentData.description.trim() === '' || agentData.instructions.trim() === '') {
      throw new Error('Please specify agent name, description, and instructions');
    }
    try {
      const response = await api.post(`/agents/${selectedProjectId}`, {
        agent_data: {
          name: agentData.name,
          instructions: agentData.instructions,
          description: agentData.description,
          model: agentData.model,
       }
      });
      const newAgent = response.data.agent;

      await fetchAvailableAgents();

      if (selectedThreadId) {
        await addAgentToConversation(newAgent.id);
      }

      if (agentData.dataSource) {
        await addAgentDataSource(newAgent.id, agentData.dataSource.data, agentData.dataSource.type);
      }

      return newAgent;
    } catch (error) {
      handleHttpError(error, 'creating agent');
    } 
  };

  const deleteAgent = async (agentId) => {
    try {
      await api.delete(`/agents/${selectedProjectId}/${agentId}`);
      setAvailableAgents(prevAgents => prevAgents.filter(agent => agent.id !== agentId));
      setActiveAgents(prevAgents => prevAgents.filter(agent => agent.id !== agentId));
    } catch (error) {
      handleHttpError(error, 'deleting agent');
    }
  };

  const getAgentData = async (agentId) => {
    if (!agentId || !selectedProjectId) return;
    try {
      const [agentData] = await Promise.all([
        fetchDataSources(selectedProjectId, agentId, 'agent'),
      ]);
      setAgentData(prev => ({ ...prev, [agentId]: agentData }));
      return agentData;
    } catch (error) {
      handleHttpError(error, 'fetching agent data sources');
    }
  };

  const addAgentDataSource = async (agentId, dataSourceRequest) => {
    if (!agentId || !selectedProjectId) {
      console.error('No agent or project selected');
      return;
    }

    try {
      console.log('Adding data source:', { agentId, dataSourceRequest });
      const result = await addDataSource(selectedProjectId, agentId, "agent", dataSourceRequest);
      if (result) {
        setAgentData(prev => ({
          ...prev,
          [agentId]: {
            ...prev[agentId],
            data_sources: [...(prev[agentId]?.data_sources || []), result]
          }
        }));
        return result;
      }
      return null;
    } catch (error) {
      console.error('Error adding data source:', error);
      handleHttpError(error, 'adding data source to agent');
    }
  };

  const removeAgentDataSource = async (agentId, dataSourceId) => {
    if (!agentId || !selectedProjectId) return;

    try {
      await removeDataSource(selectedProjectId, agentId, 'agent', dataSourceId);
      setAgentData(prev => ({
        ...prev,
        [agentId]: {
          ...prev[agentId],
          data_sources: (prev[agentId]?.data_sources || []).filter(source => source.id !== dataSourceId)
        }
      }));
    } catch (error) {
      handleHttpError(error, 'removing agent data source');
    }
  };

  const getAgentWithVersions = async (agentId) => {
    if (!selectedProjectId || !agentId) return null;
    
    try {
      const response = await api.get(`/agents/${selectedProjectId}/${agentId}`);
      setAvailableAgents(prev => prev.map(agent => 
        agent.id === agentId ? response.data.agent : agent
      ));
      return response.data;
    } catch (error) {
      handleHttpError(error, 'fetching agent versions');
    }
  };

  return {
    availableAgents,
    agentData,
    models,
    fetchModels,
    addAgentToConversation,
    removeAgentFromConversation,
    createAgent,
    deleteAgent,
    updateAgent,
    resetDefaultAgent,
    getAgentData,
    addAgentDataSource,
    removeAgentDataSource,
    refreshAgents,
    isLoadingAgents,
    getAgentWithVersions,
  };
};

export default useAgents;
