import React, { useState, useRef, useEffect } from 'react';

const RationalePopup = ({ rationale, icon, label = "Why this speaker?", location = "right" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscapeKey);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [isOpen]);

  if (!rationale) return null;

  return (
    <div className="relative inline-flex" ref={popupRef}>
      <div 
        onClick={() => setIsOpen(!isOpen)}
        className="cursor-pointer text-gray-400 hover:text-gray-300 transition-colors"
      >
        {icon}
      </div>
      {isOpen && (
        <div className={`absolute z-[100] bg-gray-800/95 backdrop-blur-sm 
                      text-sm text-gray-200 px-3 py-2 rounded-lg shadow-xl w-72 max-w-[20rem] 
                      bottom-4 ${location === "left" ? "right-0" : "left-0"} border border-gray-700/50`}>
          <div className="flex flex-col gap-1">
            <div className="text-blue-400 text-[10px] uppercase tracking-wider font-medium mb-1">
              {label}
            </div>
            <div className="flex items-start">
              <p className="text-[11px] leading-[1.3] whitespace-normal text-gray-200 pr-1">
                {rationale}
              </p>
            </div>
          </div>
          <div className={`absolute bottom-0 ${location === "left" ? "right-4" : "left-4"} transform translate-y-1/2 rotate-45 w-1.5 h-1.5 
                        bg-gray-800 border-r border-b border-gray-700/50`}></div>
        </div>
      )}
    </div>
  );
};

export default RationalePopup; 