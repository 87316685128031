export const SenderType = {
  USER: "user",
  AGENT: "agent"
};

/**
 * @typedef {Object} MessageMetadata
 * @property {string} sender_type - SenderType
 * @property {string} [name] - Optional name
 * @property {string} [icon] - Optional icon
 * @property {boolean} [is_streaming] - Optional streaming flag
 * @property {string} [streaming_id] - Optional streaming ID
 * @property {string} [short_message] - Optional summary of the message
 */

/**
 * @typedef {Object} Message
 * @property {string} [id] - Optional message ID
 * @property {string} role - Message role
 * @property {string} content - Message content
 * @property {MessageMetadata} metadata - Message metadata
 * @property {string[]} artifacts - List of artifact IDs
 * @property {number} created_at - Creation timestamp
 * @property {boolean} [edited] - Optional edit flag
 * @property {number} [editedAt] - Optional edit timestamp
 */ 