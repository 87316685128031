import React, { useEffect, useRef, useState } from 'react';
import { Edit3, Check, X, Info, CircleOff, AlertCircle, Cloud, Podcast, ChevronUp, ChevronDown, Loader2 } from 'lucide-react';
import SharedMarkdown from '../utils/SharedMarkdown';
import Workbench from './Workbench';
import { StyledTextarea } from '../utils/styledComponents';
import ErrorPopup from './ErrorPopup';
import { UserIcon, MessageAgentIcon as AgentIcon } from './ui/Icons';
import ArtifactButton from './ui/ArtifactButton';
import { SenderType } from '../types/Message';
import RationalePopup from './ui/RationalePopup';
import LoadMoreButton from './ui/LoadMoreButton';
import ToolOutputButton from './ui/ToolOutputButton';
import ToolOutputViewer from './ToolOutputViewer';
import BackgroundProcessingIndicator from './ui/BackgroundProcessingIndicator';

const MessageContainer = ({
  selectedProjectId,
  selectedThreadId,
  messages,
  isMessageLoading,
  isLoadingConversation,
  isSmallScreen,
  handleSendMessage,
  onLoadMoreMessages,
  hasMoreMessages,
  isLoadingThreads,
  workbenchVisible,
  setWorkbenchVisible,
  isAddingAgent,
  collapseInternalMessages,
  latestArtifactId,
  latestToolOutput,
  isPollingEnabled,
  onRunModeChange,
  runMode,
  runStatus,
  onCancelRun,
  updateProject,
  projectData,
  updateAgent,
  agentData,
  getAgentWithVersions,
  models,
  removeDataSource,
  addDataSource,
  getAgentData
}) => {
  const scrollRef = useRef(null);
  const [showDateMessage, setShowDateMessage] = useState(null);
  const longPressTimer = useRef(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const prevMessagesRef = useRef(messages);
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editedContent, setEditedContent] = useState('');
  const [error, setError] = useState(null);
  const [selectedArtifactId, setSelectedArtifactId] = useState(null);
  const [isWorkbenchFullscreen, setIsWorkbenchFullscreen] = useState(false);
  const [selectedToolOutput, setSelectedToolOutput] = useState(null);
  const [userHasScrolled, setUserHasScrolled] = useState(false);
  const autoScrollTimeout = useRef(null);
  const lastScrollPosition = useRef(0);
  const isManualScrolling = useRef(false);
  const editTextareaRef = useRef(null);
  const [expandedMessages, setExpandedMessages] = useState(new Set());

  useEffect(() => {
    setWorkbenchVisible(false);
  }, [selectedProjectId, selectedThreadId, setWorkbenchVisible]);

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (!scrollContainer) return;

    const shouldAutoScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
      const isNearBottom = scrollHeight - (scrollTop + clientHeight) < 100;
      const isNewUserMessage = messages[messages.length - 1]?.role === 'user';
      return !userHasScrolled || isNearBottom || isNewUserMessage;
    };

    if (messages.length > prevMessagesRef.current.length || 
        (messages.length > 0 && messages[messages.length - 1]?.content !== prevMessagesRef.current[prevMessagesRef.current.length - 1]?.content)) {
      
      if (shouldAutoScroll()) {
        setTimeout(() => {
          scrollContainer.scrollTop = scrollContainer.scrollHeight;
        }, 100);
      }
    }
    
    prevMessagesRef.current = messages.map(msg => ({
      id: msg.id,
      content: msg.content
    }));
  }, [messages, userHasScrolled]);

  useEffect(() => {
    return () => {
      if (autoScrollTimeout.current) {
        clearTimeout(autoScrollTimeout.current);
      }
    };
  }, []);

  const handleLoadMoreMessages = async () => {
    if (!isLoadingMore && hasMoreMessages) {
      const scrollContainer = scrollRef.current;
      if (!scrollContainer) return;
      
      const previousScrollHeight = scrollContainer.scrollHeight;
      const previousScrollTop = scrollContainer.scrollTop;

      setIsLoadingMore(true);
      try {
        await onLoadMoreMessages();
        
        // After messages are loaded and DOM is updated, adjust scroll position
        requestAnimationFrame(() => {
          if (scrollContainer) {
            const newScrollHeight = scrollContainer.scrollHeight;
            const scrollDiff = newScrollHeight - previousScrollHeight;
            scrollContainer.scrollTop = previousScrollTop + scrollDiff;
          }
        });

      } catch (error) {
        console.error('Error loading more messages:', error);
        setError('Failed to load more messages. Please try again.');
      } finally {
        setIsLoadingMore(false);
      }
    }
  };

  const handleArtifactClick = (artifact) => {
    setSelectedToolOutput(null);
    setSelectedArtifactId(artifact.id);
    setWorkbenchVisible(true);
  };

  useEffect(() => {
    if (selectedArtifactId) {
      setWorkbenchVisible(true);
    }
  }, [selectedArtifactId]);

  const handleMessagePress = (messageId) => {
    longPressTimer.current = setTimeout(() => {
      setShowDateMessage(messageId);
    }, 500); // Show date after 500ms of pressing
  };

  const handleMessageRelease = () => {
    clearTimeout(longPressTimer.current);
    setShowDateMessage(null);
  };

  useEffect(() => {
    if (editingMessageId && editTextareaRef.current) {
      // Scroll the textarea into view with some padding
      editTextareaRef.current.scrollIntoView({ 
        behavior: 'smooth', 
        block: 'center'
      });
      // Focus the textarea
      editTextareaRef.current.focus();
    }
  }, [editingMessageId]);

  const renderInPlaceEditing = (message) => {
    return (
      <div className="flex flex-col w-full">
        <div className="prose prose-invert max-w-none">
          <StyledTextarea
            ref={editTextareaRef}
            value={editedContent}
            onChange={(e) => {
              setEditedContent(e.target.value);
              // Adjust height automatically
              e.target.style.height = 'auto';
              e.target.style.height = `${e.target.scrollHeight}px`;
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleEditSave(message);
              }
            }}
            className="w-full p-0 min-h-[80px] resize-none overflow-hidden bg-transparent focus:outline-none text-sm font-sans text-gray-200"
            disabled={isMessageLoading}
            style={{ height: 'auto' }}
          />
        </div>
        <div className="flex justify-end mt-2">
          <button
            onClick={() => handleEditSave(message)}
            className="text-green-500 hover:text-green-700 mr-2"
            disabled={isMessageLoading}
          >
            <Check size={16} />
          </button>
          <button
            onClick={handleEditCancel}
            className="text-red-500 hover:text-red-700"
            disabled={isMessageLoading}
          >
            <X size={16} />
          </button>
        </div>
      </div>
    );
  };

  const renderMessageHeader = (message) => {
    const messageId = message.id || message.metadata?.streaming_id;
    const isExpanded = expandedMessages.has(messageId);
    const isUser = message.role === 'user';

    return (
      <div className="flex items-center w-full mb-2">
        {/* Left side: Name and icon with rationale */}
        <div className={`flex items-center gap-2 ${isUser ? 'flex-row-reverse' : 'flex-row'}`}>
          {isUser ? <UserIcon /> : <AgentIcon />}
          <div className="flex items-center gap-1">
            <span className="text-sm font-medium text-gray-300">
              {message.metadata?.name}
            </span>
            {/* Status indicators for agent messages only */}
            {!isUser && (
              <>
                {message.metadata?.run_status === 'background' && (
                  <span className="text-xs px-1.5 py-0.5 rounded-sm bg-blue-500/10 text-blue-400 flex items-center gap-1" title="Background">
                    <Cloud size={12} />
                  </span>
                )}
                {message.metadata?.run_status === 'streaming' && (
                  <span className="text-xs px-1.5 py-0.5 rounded-sm bg-blue-500/10 text-blue-400 flex items-center gap-1" title="Streaming">
                    <Podcast size={12} />
                  </span>
                )}
                {message.metadata?.run_status === 'failed' && (
                  <span className="text-xs px-1.5 py-0.5 rounded-sm bg-red-500/10 text-red-400 flex items-center gap-1">
                    <AlertCircle size={12} />
                    Failed
                  </span>
                )}
              </>
            )}
            {message.metadata?.speaker_rationale && (
              <RationalePopup 
                rationale={message.metadata.speaker_rationale}
                label="Why this speaker?"
                icon={<Info size={14} className="text-gray-300 hover:text-gray-100 transition-colors" />}
                location="right"
              />
            )}
          </div>
        </div>
        
        {/* Right side: Collapse button */}
        <div className="flex items-center gap-4 ml-auto">
          {message.metadata?.short_message && !message.metadata?.is_streaming && isExpanded && (
            <button
              onClick={() => toggleMessageExpansion(messageId)}
              className="text-gray-400 hover:text-gray-200 transition-colors p-1 rounded-full hover:bg-gray-800/40"
            >
              <ChevronUp size={16} />
            </button>
          )}
        </div>
      </div>
    );
  };

  const renderMessage = (message, index) => {
    const isUser = message.role === 'user';
    const messageId = message.id || message.metadata?.streaming_id;

    const userMessages = messages.filter(msg => msg.role === 'user');
    const lastFiveUserMessages = userMessages.slice(-5);
    const isEditable = isUser && lastFiveUserMessages.includes(message);

    // Convert message content to string if it's not already
    const messageContent = typeof message.content === 'string' ? message.content : String(message.content);

    if (message.metadata?.stop_reason === "error") {
        const error = message.metadata.error;
        return (
            <div className="flex items-center text-red-400 p-2 rounded-lg bg-red-900/20 my-2">
                <AlertCircle className="w-4 h-4 mr-2" />
                {error.type === "token_limit_exceeded" ? 
                    "Daily token limit exceeded. Please try again tomorrow." :
                    "An error occurred while generating the response."}
            </div>
        );
    }

    return (
      <div
        key={messageId || index}
        className={`my-2.5 group ${isUser ? 'user-message flex justify-end' : 'assistant-message flex justify-start'}`}
        onMouseDown={() => handleMessagePress(messageId)}
        onMouseUp={handleMessageRelease}
        onMouseLeave={handleMessageRelease}
        onTouchStart={() => handleMessagePress(messageId)}
        onTouchEnd={handleMessageRelease}
      >
        <div className={`flex flex-col ${isUser ? 'items-end' : 'items-start'} 
          ${editingMessageId === message.id ? 'w-full' : 'max-w-[90%] sm:max-w-[87%] md:max-w-[90%] lg:max-w-[93%]'}`}>
          {renderMessageHeader(message)}
          <div className={`flex items-center gap-1 ${editingMessageId === message.id ? 'w-full' : ''}`}>
            <div 
              className={`${editingMessageId === message.id ? 'w-full' : ''} text-left px-4 py-3 rounded-md
                ${isUser 
                  ? 'bg-blue-600/20 text-blue-50 shadow-sm' 
                  : 'bg-gray-800/50 text-gray-100 shadow-sm'
                } backdrop-blur-sm
                transition-colors duration-200`}
            >
              {editingMessageId === message.id ? (
                renderInPlaceEditing(message)
              ) : (
                <>
                  <div className="prose prose-invert max-w-none">
                    {/* Show short message or full content based on expansion state */}
                    {message.metadata?.short_message && !message.metadata?.is_streaming ? (
                      <>
                        {expandedMessages.has(messageId) ? (
                          <>
                            <SharedMarkdown>{message.content}</SharedMarkdown>
                            <div className="flex justify-end">
                              <button
                                onClick={() => toggleMessageExpansion(messageId)}
                                className="mt-2 text-xs text-gray-400 hover:text-gray-300 flex items-center gap-1"
                              >
                                <ChevronUp size={14} />
                                Show Less
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <SharedMarkdown>{message.metadata.short_message}</SharedMarkdown>
                            <div className="flex justify-end">
                              <button
                                onClick={() => toggleMessageExpansion(messageId)}
                                className="mt-2 text-xs text-gray-400 hover:text-gray-300 flex items-center gap-1"
                              >
                                <ChevronDown size={14} />
                                Show More
                              </button>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <SharedMarkdown>{messageContent}</SharedMarkdown>
                    )}
                  </div>
                  
                  {(message.tool_outputs?.length > 0 || message.artifacts?.length > 0 || (!isUser && message.metadata?.termination_reason)) && (
                    <div className="flex flex-wrap items-center mt-3 gap-1.5 pt-2 
                      bg-gradient-to-b from-transparent to-gray-900/20">
                      <div className="flex-grow flex flex-wrap items-center gap-1.5">
                        {message.tool_outputs?.map((output, idx) => (
                          <ToolOutputButton
                            key={idx}
                            name={output.name}
                            onClick={() => handleToolOutputClick(message, idx)}
                          />
                        ))}
                        
                        {message.artifacts?.map(artifact => (
                          <ArtifactButton
                            key={artifact.id}
                            artifact={artifact}
                            onClick={handleArtifactClick}
                          />
                        ))}
                      </div>

                      {!isUser && message.metadata?.termination_reason && (
                        <div className="ml-auto">
                          <RationalePopup
                            rationale={message.metadata.termination_reason}
                            label="Why stopped?"
                            icon={<CircleOff size={14} className="text-gray-500 hover:text-gray-300 transition-colors" />}
                            location="left"
                          />
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
            {isEditable && editingMessageId !== message.id && (
              <button
                onClick={() => handleEditClick(message)}
                className="text-gray-400 hover:text-gray-200 
                  transition-colors duration-200 p-1 rounded-full hover:bg-gray-800/40 self-center"
                disabled={isMessageLoading}
              >
                <Edit3 size={12} />
              </button>
            )}
          </div>
          {showDateMessage === message.id && (
            <span className="text-xs mt-1 text-gray-400">
              {new Date(message.created_at * 1000).toLocaleString()}
              {message.edited && " (edited)"}
            </span>
          )}
        </div>
      </div>
    );
  };

  const renderMessages = () => {
    return (
      <>
        {hasMoreMessages && (
          <LoadMoreButton
            onClick={handleLoadMoreMessages}
            isLoading={isLoadingMore}
          />
        )}
        {messages.map((message, index) => renderMessage(message, index))}
      </>
    );
  };

  const handleScroll = () => {
    isManualScrolling.current = true;
    
    const scrollContainer = scrollRef.current;
    if (!scrollContainer) return;

    const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
    const isNearBottom = scrollHeight - (scrollTop + clientHeight) < 100;
    lastScrollPosition.current = scrollTop;
    
      // Only set userHasScrolled to true if we're not near the bottom
    if (!isNearBottom) {
      setUserHasScrolled(true);
    } else {
      setUserHasScrolled(false);
    }

    // Reset manual scrolling flag after a short delay
    if (autoScrollTimeout.current) {
      clearTimeout(autoScrollTimeout.current);
    }
    autoScrollTimeout.current = setTimeout(() => {
      isManualScrolling.current = false;
    }, 150);
  };

  useEffect(() => {
    if (latestArtifactId?.id || (latestToolOutput?.message && latestToolOutput?.toolOutputIndex >= 0)) {
      // Show the most recent output based on Unix timestamps
      const artifactTime = latestArtifactId?.timestamp || 0;
      const toolTime = latestToolOutput?.timestamp || 0;

      // Convert to numbers and compare
      if (Number(artifactTime) > Number(toolTime)) {
        setSelectedArtifactId(latestArtifactId.id);
        setSelectedToolOutput(null);
      } else {
        setSelectedToolOutput({
          message: latestToolOutput.message,
          toolOutputIndex: latestToolOutput.toolOutputIndex
        });
        setSelectedArtifactId(null);
      }
      setWorkbenchVisible(true);
    }
  }, [latestArtifactId, latestToolOutput, setWorkbenchVisible]);

  useEffect(() => {
    const handleArtifactNavigation = (event) => {
      setSelectedArtifactId(event.detail.artifactId);
      setSelectedToolOutput(null);
      setWorkbenchVisible(true);
    };

    window.addEventListener('artifactNavigate', handleArtifactNavigation);
    
    return () => {
      window.removeEventListener('artifactNavigate', handleArtifactNavigation);
    };
  }, []);

  useEffect(() => {
    // Optional: Implement polling or WebSocket updates to fetch new messages for background conversations
    // Example:
    const interval = setInterval(() => {
      // Fetch new messages
    }, 5000);

    return () => clearInterval(interval);
  }, [selectedThreadId]);

  useEffect(() => {
    // Reset workbench state when thread changes
    setWorkbenchVisible(false);
    setSelectedArtifactId(null);
    setSelectedToolOutput(null);
    setIsWorkbenchFullscreen(false);
  }, [selectedThreadId]);

  const handleEditClick = (message) => {
    setEditingMessageId(message.id);
    setEditedContent(message.content);
    setWorkbenchVisible(false);
    setSelectedArtifactId(null);
    setIsWorkbenchFullscreen(false);
  };

  const handleEditCancel = () => {
    setEditingMessageId(null);
    setEditedContent('');
  };

  const handleEditSave = async (message) => {
    if (isMessageLoading) return;
    
    // Clear edit state immediately to prevent UI issues
    const savedContent = editedContent;
    setEditingMessageId(null);
    setEditedContent('');

    try {
      await handleSendMessage(
        message.created_at, 
        savedContent
      );
    } catch (error) {
      // If server request fails, show error and restore the edit state
      console.error('Error saving edited message:', error);
      setError('Failed to save edit. Please try again.');
      setEditingMessageId(message.id);
      setEditedContent(savedContent);
    }
  };

  const handleToolOutputClick = (message, toolOutputIndex) => {
    setSelectedArtifactId(null); // Clear any selected artifact
    setSelectedToolOutput({
      message,
      toolOutputIndex
    });
    setWorkbenchVisible(true);
  };

  const toggleMessageExpansion = (messageId) => {
    setExpandedMessages(prevExpandedMessages => {
      const newExpandedMessages = new Set(prevExpandedMessages);
      if (newExpandedMessages.has(messageId)) {
        newExpandedMessages.delete(messageId);
      } else {
        newExpandedMessages.add(messageId);
      }
      return newExpandedMessages;
    });
  };

  return (
    <div className={`flex-1 flex flex-col lg:flex-row overflow-hidden relative h-[calc(100vh-168px)]
      bg-gradient-to-b from-gray-900 via-gray-900 to-gray-950 backdrop-blur-sm`}>
      {/* Messages section */}
      <div 
        ref={scrollRef}
        onScroll={handleScroll}
        className={`flex-grow overflow-y-auto w-full
          ${workbenchVisible && !isWorkbenchFullscreen && !isSmallScreen ? 'lg:w-[60%]' : ''}
          ${isWorkbenchFullscreen ? 'hidden' : ''}
          scrollbar-thin scrollbar-thumb-gray-700/50 scrollbar-track-transparent`}
        style={{
          overscrollBehavior: 'none',  // Prevents bounce/rubber-band effect
          touchAction: 'pan-y pinch-zoom',  // Optimizes touch behavior for vertical scrolling
        }}
      >
      <div className="px-3 md:px-4 lg:px-6 min-h-full w-full">
        <div className="max-w-[800px] mx-auto w-full py-4">
          {renderMessages()}
          <BackgroundProcessingIndicator status={runStatus} />
          </div>
        </div>
      </div>

      {/* Workbench section with matching background */}
      {workbenchVisible && 
        (selectedArtifactId || (selectedToolOutput?.message && selectedToolOutput?.toolOutputIndex >= 0)) && (
        <div className={`${
          isWorkbenchFullscreen 
            ? 'fixed inset-0 z-50' 
            : `${isSmallScreen ? 'h-1/2' : 'lg:w-[40%] h-full'}`
        } flex-shrink-0 bg-gradient-to-b from-gray-900 via-gray-900 to-gray-950 backdrop-blur-md shadow-2xl`}>
          <div className="h-full pt-5 pb-2 px-3">
            <Workbench
              selectedArtifactId={selectedArtifactId}
              selectedToolOutput={selectedToolOutput}
              projectId={selectedProjectId}
              threadId={selectedThreadId}
              isSmallScreen={isSmallScreen}
              onClose={() => {
                setWorkbenchVisible(false);
                setSelectedArtifactId(null);
                setSelectedToolOutput(null);
                setIsWorkbenchFullscreen(false);
              }}
              isFullscreen={isWorkbenchFullscreen}
              setIsFullscreen={setIsWorkbenchFullscreen}
              updateProject={updateProject}
              projectData={projectData}
              updateAgent={updateAgent}
              agentData={agentData}
              getAgentWithVersions={getAgentWithVersions}
              models={models}
              removeDataSource={removeDataSource}
              addDataSource={addDataSource}
              getAgentData={getAgentData}
            />
          </div>
        </div>
      )}

      {/* Error popup */}
      {error && <ErrorPopup message={error} onClose={() => setError(null)} />}
    </div>
  );
};

export default MessageContainer;
