import React from 'react';
import * as Select from '@radix-ui/react-select';
import { Cloud, Podcast } from 'lucide-react';

const RunModeSelect = ({ runMode, onRunModeChange, isSmallScreen, runStatus }) => {
  return (
    <Select.Root value={runMode} onValueChange={onRunModeChange}>
      <Select.Trigger
        className={`${isSmallScreen ? 'h-8 w-8' : 'h-9 w-9'} flex items-center justify-center rounded-lg
          bg-gray-700/50 text-gray-200 hover:bg-gray-600 hover:text-blue-400
          transition-all duration-200 outline-none
          data-[state=open]:bg-gray-800`}
      >
        <Select.Value>
          {runMode === 'background' ? (
            <Cloud 
              size={16} 
              className={`text-blue-400 ${runStatus === 'background' ? 'animate-pulse' : ''}`} 
            />
          ) : (
            <Podcast 
              size={16} 
              className={`text-blue-400 ${runStatus === 'streaming' ? 'animate-pulse' : ''}`} 
            />
          )}
        </Select.Value>
      </Select.Trigger>

      <Select.Portal>
        <Select.Content
          className="z-50 overflow-hidden bg-gray-800 rounded-lg shadow-xl"
          position="popper"
          sideOffset={5}
          align="start"
        >
          <Select.Viewport>
            <Select.Item
              value="streaming"
              className="flex items-center gap-2 px-3 py-2 text-gray-200 hover:bg-gray-700 cursor-pointer outline-none"
            >
              <Podcast size={16} className="text-blue-400" />
              <Select.ItemText className="text-sm">Streaming</Select.ItemText>
            </Select.Item>
            <Select.Item
              value="background"
              className="flex items-center gap-2 px-3 py-2 text-gray-200 hover:bg-gray-700 cursor-pointer outline-none"
            >
              <Cloud size={16} className="text-blue-400" />
              <Select.ItemText className="text-sm">Background</Select.ItemText>
            </Select.Item>
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

export default RunModeSelect; 