import { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { handleHttpError } from '../utils/errorHandling';
import { toast } from 'react-hot-toast';

const API_BASE_URL = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:8080'
  : 'https://api.purposemindai.com';

const REDIRECT_URI = process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : 'https://purposemindai.com';

const useApi = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [waitlistStatus, setWaitlistStatus] = useState(null);
  const [email, setEmail] = useState(null);
  const [usage, setUsage] = useState(null);

  const api = useMemo(() => {
    const instance = axios.create({
      baseURL: API_BASE_URL,
      withCredentials: true,
    });

    instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response && error.response.status === 401) {
          setIsLoggedIn(false);
          setUser(null);
        }
        handleHttpError(error, 'API request');
        throw error;
      }
    );

    return instance;
  }, []);

  const handleLogOut = useCallback(async () => {
    try {
      await api.post('/users/logout');
      setIsLoggedIn(false);
      setUser(null);
      setIsAdmin(false);
      setWaitlistStatus(null);
    } catch (error) {
      console.error('Error during logout:', error);
      setIsLoggedIn(false);
      setUser(null);
    }
  }, [api]);

  const handleSignIn = useCallback(() => {
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    if (!clientId) {
      console.error('Google Client ID is not set');
      return;
    }
    
    try {
      // Add a dark overlay before redirect
      const overlay = document.createElement('div');
      overlay.style.position = 'fixed';
      overlay.style.top = '0';
      overlay.style.left = '0';
      overlay.style.width = '100%';
      overlay.style.height = '100%';
      overlay.style.backgroundColor = '#111827'; // matches bg-gray-900
      overlay.style.zIndex = '9999';
      document.body.appendChild(overlay);

      const authUrl = new URL('https://accounts.google.com/o/oauth2/v2/auth');
      authUrl.searchParams.append('client_id', clientId);
      authUrl.searchParams.append('redirect_uri', REDIRECT_URI);
      authUrl.searchParams.append('response_type', 'code');
      authUrl.searchParams.append('scope', 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email');
      authUrl.searchParams.append('access_type', 'offline');
      authUrl.searchParams.append('prompt', 'consent');

      // Short delay to ensure overlay is visible
      setTimeout(() => {
        window.location.href = authUrl.toString();
      }, 50);
    } catch (error) {
      console.error('Error initiating Google sign in:', error);
      handleHttpError(error, 'Failed to initiate sign in');
    }
  }, []);

  const checkAuth = useCallback(async () => {
    try {
      const response = await api.get('/users/me');
      const userData = response.data;
      setUser(userData);
      setIsLoggedIn(true);
      setIsAdmin(userData.is_admin);
    } catch (error) {
      setIsLoggedIn(false);
      setUser(null);
      setIsAdmin(false);
    } finally {
      setIsLoading(false);
    }
  }, [api]);

  const handleAuthCallback = useCallback(async (code) => {
    try {
      if (!code) {
        console.error('No authorization code provided');
        return;
      }

      // First check waitlist status
      const waitlistResponse = await api.post('/users/waitlist/check', { code });
      
      if (waitlistResponse.data.status === 'error') {
        console.error('Error checking waitlist:', waitlistResponse.data.message);
        throw new Error(waitlistResponse.data.message);
      }

      if (waitlistResponse.data.status === 'approved') {
        const userData = waitlistResponse.data.user;
        if (userData) {
          setUser(userData);
          setIsLoggedIn(true);
          setIsAdmin(userData.is_admin);
        }
      } else {
        setWaitlistStatus(waitlistResponse.data.status);
        setIsLoggedIn(false);
        return waitlistResponse.data;
      }

      // Clean up URL parameters
      window.history.replaceState({}, document.title, window.location.pathname);

      return waitlistResponse.data;
    } catch (error) {
      console.error('Error in auth callback:', error);
      // Clean up URL parameters even on error
      window.history.replaceState({}, document.title, window.location.pathname);

      if (error.response?.status === 403) {
        setWaitlistStatus('pending');
        setIsLoggedIn(false);
        return {
          status: 'pending',
          message: error.response.data.detail || 'Please wait for waitlist approval.'
        };
      }
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [api]);

  const handleWaitlistSignup = useCallback(async (email) => {
    try {
      const response = await api.post('/users/waitlist/add', { 
        email,
        name: null,
        picture: null
      });
      setWaitlistStatus(response.data.status);
      return response.data;
    } catch (error) {
      console.error('Error signing up for waitlist:', error);
      handleHttpError(error, 'Waitlist signup failed');
      throw error;
    }
  }, [api]);

  const handleError = (error) => {
    if (error.response?.data?.detail === 'Failed to get usage') {
        toast.error('Unable to load usage data');
    } else if (error.response?.data?.detail === 'Monthly usage limit exceeded') {
        toast.error('Monthly usage limit reached');
    } else {
        toast.error(error.response?.data?.detail || 'An error occurred');
    }
  };

  const getUsage = useCallback(async () => {
    try {
      const response = await api.get('/users/tokens');
      setUsage(response.data);
      return response.data;
    } catch (error) {
      handleError(error);
      return null;
    }
  }, [api, handleError]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    
    if (code) {
      setIsLoading(true);
      handleAuthCallback(code).catch(() => {
        setIsLoading(false);
      });
    } else {
      checkAuth();
    }
  }, [handleAuthCallback, checkAuth]);

  // Fetch usage when api is ready
  useEffect(() => {
    if (api) {
      getUsage();
    }
  }, [api]);

  return {
    isLoggedIn,
    setIsLoggedIn,
    isLoading,
    user,
    setUser,
    handleSignIn,
    handleLogOut,
    handleWaitlistSignup,
    api,
    isAdmin,
    waitlistStatus,
    setWaitlistStatus,
    email,
    handleError,
    getUsage,
    usage,
  };
};

export default useApi;
