import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Dialog } from '@headlessui/react';
import Chat from './Chat';
import TermsAndConditions from './components/TermsAndConditions';
import Footer from './components/Footer';
import useApi from './hooks/useApi';
import HomePage from './components/HomePage';
import CookieConsent from './components/CookieConsent';
import LoadingSpinner from './components/LoadingSpinner';
import ErrorBoundary from './components/ErrorBoundary';
import ErrorPopup from './components/ErrorPopup';
import './App.css';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsPage from './pages/TermsPage';
import PrivacyPage from './pages/PrivacyPage';
import Header from './components/Header';

function App() {
    const { 
        isLoggedIn, 
        setIsLoggedIn, 
        isLoading, 
        user, 
        setUser, 
        handleLogOut,
        handleSignIn,
        api,
        isAdmin,
        waitlistStatus,
        handleWaitlistSignup,
        setWaitlistStatus,
        email
    } = useApi();
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [showCookieConsent, setShowCookieConsent] = useState(false);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [error, setError] = useState(null);
    const [showTerms, setShowTerms] = useState(false);

    // Add global error handler
    useEffect(() => {
        const handleGlobalError = (event) => {
            event.preventDefault();
            setError(event.error?.message || 'An unexpected error occurred');
        };

        // Handle promise rejections
        const handleUnhandledRejection = (event) => {
            event.preventDefault();
            setError(event.reason?.message || 'An unexpected error occurred');
        };

        window.addEventListener('error', handleGlobalError);
        window.addEventListener('unhandledrejection', handleUnhandledRejection);

        return () => {
            window.removeEventListener('error', handleGlobalError);
            window.removeEventListener('unhandledrejection', handleUnhandledRejection);
        };
    }, []);

    useEffect(() => {
        const termsAccepted = localStorage.getItem('agreedToTerms') === 'true';
        setAgreedToTerms(termsAccepted);

        const cookiesAccepted = localStorage.getItem('cookiesAccepted');
        if (!isLoggedIn && cookiesAccepted === null) {
            setShowCookieConsent(true);
        } else {
            setShowCookieConsent(false);
        }
    }, [isLoggedIn]);

    const handleAgree = () => {
        try {
            setAgreedToTerms(true);
            localStorage.setItem('agreedToTerms', 'true');
        } catch (error) {
            setError('Failed to save agreement settings');
        }
    };

    const handleDisagree = () => {
        localStorage.removeItem('agreedToTerms');
        setAgreedToTerms(false);
        handleLogOut(); // Log out the user if they disagree
    };

    const handleCookieAccept = () => {
        localStorage.setItem('cookiesAccepted', 'true');
        setShowCookieConsent(false);
    };

    const handleCookieDeny = () => {
        localStorage.setItem('cookiesAccepted', 'false');
        setShowCookieConsent(false);
    };

    const handleWaitlistSignIn = async () => {
        const code = new URLSearchParams(window.location.search).get('code');
        
        // If no code is present, trigger the OAuth flow
        if (!code) {
            try {
                await handleSignIn();
                return null; // Return null since this will trigger a redirect
            } catch (error) {
                setError('Failed to initiate signup');
                throw error;
            }
        }

        // If code is present, check waitlist status
        try {
            const response = await api.post('/users/waitlist/check', { code });
            
            if (response.data.status === 'approved') {
                setIsLoggedIn(true);
                setUser(response.data.user);
            }
            return response.data;
        } catch (error) {
            setError('Failed to process signup');
            throw error;
        }
    };

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <ErrorBoundary>
            <Router>
                <Routes>
                    <Route path="/terms" element={<TermsPage />} />
                    <Route path="/privacy" element={<PrivacyPage />} />
                    <Route path="/" element={
                        <div className="flex-container bg-gray-900 text-white">
                            <main className="main-content flex flex-col">
                                {!isLoggedIn && (
                                    <>
                                        <HomePage 
                                            handleSignIn={handleSignIn}
                                            handleWaitlistSignup={handleWaitlistSignup}
                                            waitlistStatus={waitlistStatus}
                                            setWaitlistStatus={setWaitlistStatus}
                                            email={email}
                                        />
                                        <CookieConsent
                                            isOpen={showCookieConsent}
                                            onAccept={handleCookieAccept}
                                            onDeny={handleCookieDeny}
                                            setShowTerms={() => setAgreedToTerms(false)}
                                        />
                                    </>
                                )}
                                {isLoggedIn && (
                                    <>
                                        {!agreedToTerms && 
                                            <Dialog open={true} onClose={() => {}} className="fixed z-10 inset-0 overflow-y-auto">
                                                <div className="flex items-center justify-center min-h-screen">
                                                    <div className="fixed inset-0 bg-black opacity-50" aria-hidden="true" />                                    
                                                    <div className="bg-gray-800 text-gray-200 rounded-lg p-6 max-w-lg w-full mx-auto max-h-[80vh] overflow-y-auto z-20 border border-gray-700">
                                                        <TermsAndConditions 
                                                            onAgree={handleAgree} 
                                                            onDisagree={handleDisagree} 
                                                            showButtons={true}
                                                            onNavigateToPrivacyPolicy={() => setShowPrivacyPolicy(true)}
                                                        />
                                                    </div>
                                                </div>
                                            </Dialog>
                                        }
                                        {showPrivacyPolicy && 
                                            <Dialog open={showPrivacyPolicy} onClose={() => setShowPrivacyPolicy(false)} className="fixed z-10 inset-0 overflow-y-auto">
                                                <div className="flex items-center justify-center min-h-screen">
                                                    <div className="fixed inset-0 bg-black opacity-50" aria-hidden="true" />
                                                    <div className="bg-gray-800 text-gray-200 rounded-lg p-6 max-w-lg w-full mx-auto max-h-[80vh] overflow-y-auto z-20 border border-gray-700">
                                                        <PrivacyPolicy onClose={() => setShowPrivacyPolicy(false)} />
                                                    </div>
                                                </div>
                                            </Dialog>
                                        }
                                        {agreedToTerms && 
                                            <Chat 
                                                isLoggedIn={isLoggedIn}
                                                user={user} 
                                                handleSignIn={handleWaitlistSignIn}
                                                handleLogOut={handleLogOut}
                                                isAdmin={isAdmin}
                                                api={api}
                                            />
                                        }
                                    </>
                                )}
                            </main>
                            {!isLoggedIn && (
                                <Footer
                                    setShowTerms={setShowTerms}
                                    setShowPrivacyPolicy={setShowPrivacyPolicy}
                                    className="footer bg-gray-900 text-gray-200"
                                />
                            )}
                            {error && <ErrorPopup message={error} onClose={() => setError(null)} />}
                        </div>
                    } />
                </Routes>
            </Router>
        </ErrorBoundary>
    );
}

export default App;
