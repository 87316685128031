import React from 'react';
import { Cloud, Loader2 } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const BackgroundProcessingIndicator = ({ status }) => {
  if (status !== 'background') return null;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="flex items-center justify-center py-4 text-blue-400"
      >
        <motion.div
          initial={{ scale: 0.95 }}
          animate={{ 
            scale: 1,
            transition: {
              duration: 0.2,
              ease: "easeOut"
            }
          }}
          className="flex items-center gap-3 px-5 py-3 bg-blue-500/10 rounded-lg"
        >
          <motion.div
            animate={{ 
              opacity: [0.5, 1, 0.5],
              scale: [0.98, 1, 0.98],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: "easeInOut"
            }}
            className="flex items-center gap-2"
          >
            <Cloud className="w-4 h-4" />
            <span className="text-sm font-medium">Processing</span>
          </motion.div>
          <motion.div 
            className="flex items-center gap-1"
            animate={{ opacity: [0.5, 1, 0.5] }}
            transition={{
              duration: 1.5,
              repeat: Infinity,
              ease: "easeInOut",
              delay: 0.2
            }}
          >
            {[...Array(3)].map((_, i) => (
              <motion.div
                key={i}
                animate={{ 
                  y: [-1, 1, -1],
                  scale: [1, 1.2, 1]
                }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  delay: i * 0.2,
                  ease: "easeInOut"
                }}
                className="w-1 h-1 bg-blue-400 rounded-full"
              />
            ))}
          </motion.div>
          <motion.div
            animate={{ rotate: 360 }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: "linear"
            }}
          >
            <Loader2 className="w-3 h-3 ml-1" />
          </motion.div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default BackgroundProcessingIndicator; 