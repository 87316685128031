import React from 'react';
import { Search, X, Users, User } from 'lucide-react';

const FilterBar = ({ 
  showShared, 
  setShowShared,
  searchTerm,
  setSearchTerm,
  itemType = "projects",
  filterLabel = "My",
  isSmallScreen,
  isExpanded,
  showToggle = true
}) => {
  return (
    <div className={`
      transition-all duration-200 ease-in-out
      ${isExpanded ? `opacity-100 max-h-[100px] ${showToggle ? 'mr-4' : ''} mb-2` : 'opacity-0 max-h-0 overflow-hidden'}
    `}>
      <div className={`flex items-center gap-2 bg-gray-800/20 backdrop-blur-sm ${showToggle ? 'p-2' : 'p-1.5'} rounded-lg w-full mt-2`}>
        {showToggle && (
          <div className="flex items-center flex-shrink-0 gap-1.5">
            <button
              onClick={() => setShowShared(true)}
              className={`px-2 py-0.5 text-sm rounded-md transition-colors flex items-center gap-1 ${
                showShared 
                  ? 'bg-gray-800/30 text-gray-200' 
                  : 'text-gray-400 hover:text-gray-300 hover:bg-gray-800/20'
              }`}
            >
              {isSmallScreen ? (
                <Users className="h-4 w-4" />
              ) : (
                'All'
              )}
            </button>
            <button
              onClick={() => setShowShared(false)}
              className={`px-2 py-0.5 text-sm rounded-md transition-colors flex items-center gap-1 ${
                !showShared 
                  ? 'bg-gray-800/30 text-gray-200' 
                  : 'text-gray-400 hover:text-gray-300 hover:bg-gray-800/20'
              }`}
            >
              {isSmallScreen ? (
                <User className="h-4 w-4" />
              ) : (
                `${filterLabel} ${itemType}`
              )}
            </button>
          </div>
        )}

        <div className={`relative ${showToggle ? 'flex-1' : 'w-full'}`}>
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
              <Search className="h-3.5 w-3.5 text-gray-400" />
            </div>
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={`w-full pl-9 pr-9 py-2 text-sm bg-gray-800/30 text-gray-200 
                rounded-lg focus:outline-none focus:ring-0 
                placeholder-gray-500 ${isSmallScreen ? 'text-center' : ''}`}
              placeholder={`Search ${itemType}...`}
            />
            {searchTerm && (
              <button
                onClick={() => setSearchTerm('')}
                className="absolute inset-y-0 right-3 flex items-center"
              >
                <X className="h-3.5 w-3.5 text-gray-400 hover:text-gray-300" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterBar;