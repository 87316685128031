import React, { useRef, useEffect } from 'react';
import { Target, TrendingUp, Plus } from 'lucide-react';
import Button from './ui/Button';

const ProjectForm = ({ 
  projectData = {
    name: '',
    objective: '',
    keyResults: [],
    metrics: [],
    outputFormat: 'Auto'
  }, 
  setProjectData, 
  readOnly = false 
}) => {
  // Add useRef for textarea
  const objectiveRef = useRef(null);

  // Function to adjust height
  const adjustTextareaHeight = (element) => {
    if (element) {
      element.style.height = 'auto';
      element.style.height = `${element.scrollHeight}px`;
    }
  };

  // Adjust height on content change
  useEffect(() => {
    if (objectiveRef.current) {
      adjustTextareaHeight(objectiveRef.current);
    }
  }, [projectData.objective]);

  // Handler functions for editable form
  const handleAddKeyResult = () => {
    if (!readOnly) {
      setProjectData(prev => ({
        ...prev,
        keyResults: [...(prev.keyResults || []), { description: '' }]
      }));
    }
  };

  const handleAddMetric = () => {
    if (!readOnly) {
      setProjectData(prev => ({
        ...prev,
        metrics: [...prev.metrics, { 
          name: '',
          target: '',
          current: ''
        }]
      }));
    }
  };

  // Read-only view
  if (readOnly) {
    return (
      <div className="space-y-6">
        {/* Project Name */}
        <div>
          <h3 className="text-sm font-medium text-blue-100">Project Name</h3>
          <p className="text-gray-200 bg-gray-700/50 px-4 py-2 rounded-md mt-2">
            {projectData.name}
          </p>
        </div>

        {/* Objective */}
        {projectData.objective && (
          <div>
            <h3 className="text-sm font-medium text-gray-200">Objective</h3>
            <p className="text-gray-200 bg-gray-700/50 px-4 py-2 rounded-md mt-2 whitespace-pre-wrap">
              {projectData.objective}
            </p>
          </div>
        )}

        {/* Key Results */}
        {projectData.keyResults?.length > 0 && (
          <div>
            <h3 className="text-sm font-medium text-blue-100">Key Results</h3>
            <div className="space-y-2 mt-2">
              {projectData.keyResults.map((kr, index) => (
                <div key={index} className="text-gray-200 bg-gray-700/50 px-4 py-2 rounded-md">
                  {kr.description}
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Metrics */}
        {projectData.metrics?.length > 0 && (
          <div>
            <h3 className="text-sm font-medium text-gray-200">Metrics</h3>
            <div className="space-y-2 mt-2">
              {projectData.metrics.map((metric, index) => (
                <div key={index} className="flex justify-between text-gray-200 bg-gray-700/50 px-4 py-2 rounded-md">
                  <span>{metric.name}</span>
                  {metric.target && <span>Target: {metric.target}</span>}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }

  // Editable form
  return (
    <div className="space-y-6">
      {/* Project Name */}
      <div>
        <label className="block text-sm font-medium text-blue-100">
          Project Name
        </label>
        <input
          type="text"
          value={projectData.name}
          onChange={(e) => setProjectData(prev => ({ ...prev, name: e.target.value }))}
          className="w-full px-4 py-2 bg-gray-700/50 rounded-md mt-2 text-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-500/50"
          placeholder="Enter project name"
          required
          readOnly={readOnly}
        />
      </div>

      {/* Objective */}
      <div>
        <label className="block text-sm font-medium text-gray-200">
          Objective
        </label>
        <textarea
          ref={objectiveRef}
          value={projectData.objective}
          onChange={(e) => {
            setProjectData(prev => ({ ...prev, objective: e.target.value }));
          }}
          className="w-full px-4 py-2 bg-gray-700/50 rounded-md mt-2 text-gray-200 resize-none focus:outline-none focus:ring-2 focus:ring-blue-500/50"
          placeholder="What are you trying to achieve?"
          readOnly={readOnly}
        />
      </div>

      {/* Key Results */}
      <div>
        <div className="flex justify-between items-center">
          <label className="text-sm font-medium text-blue-100">
            Key Results
          </label>
          {!readOnly && (
            <Button
              variant="ghost"
              onClick={handleAddKeyResult}
              size="sm"
              icon={<Target size={16} />}
              className="text-blue-400 hover:text-blue-300 bg-gray-700/30 hover:bg-gray-700"
            >
              New KR
            </Button>
          )}
        </div>
        <div className="space-y-3 mt-2">
          {(projectData.keyResults || []).map((kr, index) => (
            <div key={index} className="flex gap-2">
              <input
                value={kr.description}
                onChange={(e) => {
                  const newKRs = [...projectData.keyResults];
                  newKRs[index] = { description: e.target.value };
                  setProjectData(prev => ({ ...prev, keyResults: newKRs }));
                }}
                className="flex-1 px-4 py-2 bg-gray-700/50 rounded-md text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500/50"
                placeholder="What's the measurable outcome?"
                readOnly={readOnly}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Metrics */}
      <div>
        <div className="flex justify-between items-center">
          <label className="text-sm font-medium text-gray-200">
            Metrics
          </label>
          {!readOnly && (
            <Button
              variant="ghost"
              onClick={handleAddMetric}
              size="sm"
              icon={<TrendingUp size={16} />}
              className="text-blue-400 hover:text-blue-300 bg-gray-700/30 hover:bg-gray-700"
            >
              New Metric
            </Button>
          )}
        </div>
        <div className="space-y-3 mt-2">
          {projectData.metrics.map((metric, index) => (
            <div key={index} className="flex gap-2">
              <input
                value={metric.name}
                onChange={(e) => {
                  const newMetrics = [...projectData.metrics];
                  newMetrics[index] = { ...metric, name: e.target.value };
                  setProjectData(prev => ({ ...prev, metrics: newMetrics }));
                }}
                className="flex-1 px-4 py-2 bg-gray-700/50 rounded-md text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500/50"
                placeholder="Metric name"
                readOnly={readOnly}
              />
              <input
                value={metric.target}
                onChange={(e) => {
                  const newMetrics = [...projectData.metrics];
                  newMetrics[index] = { ...metric, target: e.target.value };
                  setProjectData(prev => ({ ...prev, metrics: newMetrics }));
                }}
                className="w-24 px-4 py-2 bg-gray-700/50 rounded-md text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500/50"
                placeholder="Target"
                readOnly={readOnly}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectForm; 