import React, { useState, useEffect, useCallback } from 'react';
import { 
  ChevronLeft, 
  ChevronRight,
  Filter,
  FolderPlus,
  Bot,
  Loader2
} from 'lucide-react';
import AgentCard from './cards/AgentCard';
import ProjectCard from './cards/ProjectCard';
import LoadingButton from './ui/LoadingButton';
import Button from './ui/Button';
import FilterBar from './FilterBar';

String.prototype.hashCode = function() {
  let hash = 0;
  for (let i = 0; i < this.length; i++) {
    const char = this.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash;
  }
  return hash;
};

const CarouselRow = ({ 
  items, 
  onItemSelect, 
  selectedId, 
  onEditItem,
  onShareItem,
  onDeleteItem,
  itemType = 'project',
  getAgentWithVersions,
  onCreateNew,
  isCreating,
  onFilterClick,
  showFilters,
  showShared,
  setShowShared,
  searchTerm,
  setSearchTerm,
  onDoubleClick,
  models,
  addDataSource,
  removeDataSource,
  agentData,
  getAgentData,
  deleteAgent
}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (selectedId && itemType === 'project') {
      const selectedIndex = items.findIndex(item => item.id === selectedId);
      if (selectedIndex !== -1) {
        setTimeout(() => {
          const selectedElement = document.querySelector(`[data-project-id="${selectedId}"]`);
          if (selectedElement) {
            selectedElement.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'center'
            });
          }
        }, 300);
      }
    }
  }, [selectedId, items, itemType]);

  const checkScrollButtons = useCallback((container) => {
    if (!container) return;
    
    const hasScrollLeft = container.scrollLeft > 0;
    const hasScrollRight = container.scrollLeft < (container.scrollWidth - container.clientWidth);
    
    setCanScrollLeft(hasScrollLeft);
    setCanScrollRight(hasScrollRight);
  }, []);

  useEffect(() => {
    const container = document.querySelector(`.carousel-container-${itemType}`);
    if (!container) return;

    // Check initial scroll buttons state
    checkScrollButtons(container);

    // Add scroll listener
    const handleScroll = () => checkScrollButtons(container);
    container.addEventListener('scroll', handleScroll);
    
    return () => container.removeEventListener('scroll', handleScroll);
  }, [itemType, checkScrollButtons]);

  const handleScroll = (direction) => {
    const container = document.querySelector(`.carousel-container-${itemType}`);
    if (!container) return;

    if (direction === 'left' && canScrollLeft) {
      container.scrollBy({
        left: -container.offsetWidth / 3,
        behavior: 'smooth'
      });
    } else if (direction === 'right' && canScrollRight) {
      container.scrollBy({
        left: container.offsetWidth / 3,
        behavior: 'smooth'
      });
    }
  };

  const renderItem = (item) => {
    switch (itemType) {
      case 'project':
        return (
          <ProjectCard
            project={item}
            onSelect={onItemSelect}
            onEdit={onEditItem}
            onShare={onShareItem}
            onDelete={onDeleteItem}
            isSelected={selectedId === item.id}
          />
        );

      case 'agent':
        return (
          <AgentCard
            agent={item}
            onSelect={onItemSelect}
            onEdit={onEditItem}
            onDelete={deleteAgent}
            getAgentWithVersions={getAgentWithVersions}
            getAgentData={getAgentData}
            models={models}
            addDataSource={addDataSource}
            removeDataSource={removeDataSource}
            agentData={agentData}
          />
        );

      default:
        return null;
    }
  };

  const renderPlaceholder = () => (
    <div className="flex-none w-[calc(33.333%-0.75rem)] h-full">
      <div className="w-full h-full border-2 border-dashed border-gray-200 rounded-xl bg-gray-50/50"></div>
    </div>
  );

  const renderItems = () => {
    const itemElements = items.map((item) => (
      <div 
        key={item.id}
        data-project-id={item.id}
        className={`flex-none ${
          isSmallScreen 
            ? 'w-[80vw] snap-center'
            : 'w-[calc(33.333%-0.667rem)]'
        }`}
        style={{ 
          minWidth: isSmallScreen ? '80vw' : 'calc(33.333% - 0.667rem)',
          scrollSnapAlign: isSmallScreen ? 'center' : 'none',
          scrollSnapStop: isSmallScreen ? 'always' : 'normal'
        }}
      >
        {renderItem(item)}
      </div>
    ));

    const containerClasses = `flex transition-transform duration-300 ease-in-out ${
      isSmallScreen ? 'gap-1' : 'gap-3'
    } ${items.length <= 2 ? 'justify-center' : ''}`;

    return (
      <div className={containerClasses}>
        {itemElements}
      </div>
    );
  };

  // Add useEffect to check for overflow
  useEffect(() => {
    const container = document.querySelector(`.carousel-container-${itemType}`);
    if (!container) return;

    // Check if content is wider than container
    const checkOverflow = () => {
      const hasOverflow = container.scrollWidth > container.clientWidth;
      setCanScrollRight(hasOverflow);
    };

    // Initial check
    checkOverflow();

    // Add resize observer to check for overflow on resize
    const resizeObserver = new ResizeObserver(checkOverflow);
    resizeObserver.observe(container);

    return () => resizeObserver.disconnect();
  }, [itemType, items]);

  // Update the scroll button visibility logic
  const showScrollButtons = !isSmallScreen && itemType !== 'conversation';

  const handleEdit = async (e, item) => {
    e.stopPropagation();
    if (itemType === 'agent' && item.id) {
      await getAgentWithVersions(item.id);
    }
    onEditItem(item);
  };

  const getTitleAndIcon = () => {
    switch (itemType) {
      case 'project':
        return {
          title: 'Available Projects',
          icon: <Filter className={`h-3.5 w-3.5 ${showFilters ? 'text-gray-200' : 'text-gray-400'}`} />,
          newIcon: <FolderPlus className="h-3.5 w-3.5" />
        };
      case 'agent':
        return {
          title: 'Active Agents',
          icon: <Filter className={`h-3.5 w-3.5 ${showFilters ? 'text-gray-200' : 'text-gray-400'}`} />,
          newIcon: <Bot className="h-3.5 w-3.5" />
        };
      default:
        return { title: '', icon: null, newIcon: null };
    }
  };

  const { title, icon, newIcon } = getTitleAndIcon();

  useEffect(() => {
    const container = document.querySelector(`.carousel-container-${itemType}`);
    if (!container) return;
    
    const handleScroll = () => checkScrollButtons(container);
    container.addEventListener('scroll', handleScroll);
    
    const cleanup = () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };

    window.addEventListener('beforeunload', cleanup);
    return () => {
      cleanup();
      window.removeEventListener('beforeunload', cleanup);
    };
  }, [itemType, checkScrollButtons]);

  return (
    <div className="w-full">
      <div className="flex flex-col w-full px-4 mb-2">
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center gap-1">
            <button 
              onClick={onFilterClick}
              aria-label={`Toggle ${itemType} filters`}
              aria-expanded={showFilters}
              className="text-gray-400 hover:text-gray-200 p-1 hover:bg-gray-800/30 rounded-lg transition-colors"
            >
              {icon}
            </button>
            <p className="text-sm font-medium text-gray-400">{title}</p>
          </div>
          {onCreateNew && (
            <Button
              variant="ghost"
              size="sm"
              onClick={onCreateNew}
              disabled={isCreating}
              className="text-gray-400 hover:text-gray-200 flex items-center gap-1 px-2 
                bg-gray-800/30 hover:bg-gray-800/50 backdrop-blur-sm rounded-lg mr-2"
            >
              {isCreating ? (
                <Loader2 className="h-4 w-4 animate-spin" />
              ) : (
                newIcon
              )}
              <span className={`text-sm ${isSmallScreen ? 'hidden' : 'inline'}`}>
                New {itemType}
              </span>
            </Button>
          )}
        </div>
        
        <FilterBar
          showShared={showShared}
          setShowShared={setShowShared}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          itemType={itemType}
          isSmallScreen={isSmallScreen}
          isExpanded={showFilters}
        />
      </div>

      <div className="relative bg-gray-700 rounded-lg">
        {showScrollButtons && canScrollLeft && (
          <button
            title="Previous"
            onClick={() => handleScroll('left')}
            className={`absolute left-4 top-1/2 -translate-y-1/2 z-10 p-1.5 ${
              isSmallScreen 
                ? 'bg-gray-600 text-gray-200'
                : 'bg-white text-gray-600'
            } rounded-full shadow-lg hover:bg-opacity-90`}
          >
            <ChevronLeft className='h-3.5 w-3.5' />
          </button>
        )}

        <div className={`overflow-x-auto scrollbar-hide carousel-container-${itemType} px-4`}>
          {renderItems()}
        </div>

        {showScrollButtons && canScrollRight && (
          <button
            title="Next"
            onClick={() => handleScroll('right')}
            className={`absolute right-4 top-1/2 -translate-y-1/2 z-10 p-1.5 ${
              isSmallScreen 
                ? 'bg-gray-600 text-gray-200'
                : 'bg-white text-gray-600'
            } rounded-full shadow-lg hover:bg-opacity-90`}
          >
            <ChevronRight className='h-3.5 w-3.5' />
          </button>
        )}
      </div>
    </div>
  );
};

export default CarouselRow; 