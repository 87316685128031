import React from 'react';
import { getToolConfig } from '../../utils/toolConfigs';

const ToolOutputButton = ({ name, onClick }) => {
  const config = getToolConfig(name);
  const IconComponent = config.icon;

  return (
    <button
      onClick={onClick}
      className={`flex items-center gap-1.5 text-xs bg-gray-800 hover:bg-gray-700
        text-gray-300 hover:text-gray-100 rounded-md px-2.5 py-1.5 
        transition-colors shadow-sm hover:shadow-md`}
    >
      <IconComponent size={12} className={config.color} />
      <span className="font-medium">{name}</span>
    </button>
  );
};

export default ToolOutputButton; 