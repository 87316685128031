import { useState, useEffect, useCallback, useRef } from 'react';
import useApi from './useApi';
import { handleHttpError } from '../utils/errorHandling';
import useDataSources from './useDataSources';
import { MessageVisibility, SenderType } from '../types/Message';

const LOAD_MESSAGES_LIMIT = 50;

const useConversations = ({ selectedProjectId }) => {
  const { api } = useApi();
  const { fetchDataSources, addDataSource, removeDataSource } = useDataSources();

  const [threads, setThreads] = useState([]);
  const [selectedThreadId, setSelectedThreadId] = useState(null);
  const [currentThreadTitle, setCurrentThreadTitle] = useState('');
  const [moreThreadsAvailable, setMoreThreadsAvailable] = useState(false);
  const [messages, setMessages] = useState([]);
  const [activeAgents, setActiveAgents] = useState([]);
  const [conversationData, setConversationData] = useState([]);
  const [isUpdatingConversationDataSource, setIsUpdatingConversationDataSource] = useState(false);
  const [isLoadingConversation, setIsLoadingConversation] = useState(false);
  const [hasMoreMessages, setHasMoreMessages] = useState(false);
  const [isDeletingThread, setIsDeletingThread] = useState(false);
  const [lastConversationData, setLastConversationData] = useState(null);

  const [isLoadingThreads, setIsLoadingThreads] = useState(true);
  const [isLoadingDataSources, setIsLoadingDataSources] = useState(false);

  const LIMIT = 20;
  const [page, setPage] = useState(0);

  const [isPollingEnabled, setIsPollingEnabled] = useState(false);
  const pollingIntervalRef = useRef(null);
  const lastMessageTimestampRef = useRef(null);

  const [conversationMetadata, setConversationMetadata] = useState(null);

  const [expandedMessages, setExpandedMessages] = useState(new Set());

  const toggleMessageExpansion = useCallback((messageId) => {
    setExpandedMessages(prev => {
      const newSet = new Set(prev);
      if (newSet.has(messageId)) {
        newSet.delete(messageId);
      } else {
        newSet.add(messageId);
      }
      return newSet;
    });
  }, []);

  useEffect(() => {
    setThreads([]);
    setSelectedThreadId(null);
    setMessages([]);
    setActiveAgents([]);
  }, [selectedProjectId]);

  useEffect(() => {
    const storedData = localStorage.getItem('lastConversation');
    if (storedData) {
      setLastConversationData(JSON.parse(storedData));
    }
  }, []);

  const stopPolling = useCallback(() => {
    if (!pollingIntervalRef.current) {
      return;
    }
    
    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current);
      pollingIntervalRef.current = null;
    }
    setIsPollingEnabled(false);
    lastMessageTimestampRef.current = null;
    console.log('Polling stopped', { 
      threadId: selectedThreadId 
    });
  }, [selectedThreadId]);

  const fetchNewMessages = useCallback(async () => {
    if (!selectedThreadId || !selectedProjectId) return;

    try {
      const response = await api.get(`/conversations/${selectedProjectId}/${selectedThreadId}/messages`, {
        params: {
          after: lastMessageTimestampRef.current,
          limit: LOAD_MESSAGES_LIMIT
        }
      });

      const { messages: newMessages, run_status } = response.data;
      
      console.log('Polling received:', { 
        newMessages: newMessages?.length, 
        status: run_status, 
        lastTimestamp: lastMessageTimestampRef.current 
      });

      // Update conversation metadata with new status
      setConversationMetadata(prev => ({
        ...prev,
        run_status
      }));

      // Process any new messages first
      if (newMessages && newMessages.length > 0) {
        // Update the last message timestamp for next poll
        const latestMessage = newMessages[newMessages.length - 1];
        lastMessageTimestampRef.current = latestMessage.created_at;

        setMessages(prevMessages => {
          const existingIds = new Set(prevMessages.map(msg => msg.id));
          const uniqueNewMessages = newMessages
            .filter(msg => !existingIds.has(msg.id))
            .map(msg => ({
              ...msg,
              metadata: {
                ...msg.metadata
              }
            }));

          if (uniqueNewMessages.length === 0) return prevMessages;

          return [...prevMessages, ...uniqueNewMessages];
        });
      }

      // Only stop polling if we're in a terminal state AND we have no new messages
      if (['completed', 'failed', 'retriable'].includes(run_status) && (!newMessages || newMessages.length === 0)) {
        setTimeout(() => {
          stopPolling();
          setConversationMetadata({ run_status });
        }, 100);
      }
    } catch (error) {
      console.error('Error fetching new messages:', error);
      stopPolling(); // Stop polling on error
      setConversationMetadata({ run_status: 'failed' });
    }
  }, [api, selectedProjectId, selectedThreadId, stopPolling, setConversationMetadata]);

  const startPolling = useCallback(() => {
    // First stop any existing polling
    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current);
      pollingIntervalRef.current = null;
    }

    setIsPollingEnabled(true);
    // Set initial timestamp from the last message we have
    const lastMessage = messages[messages.length - 1];
    console.log('Starting polling with last message:', {
      timestamp: lastMessage?.created_at,
      messageId: lastMessage?.id,
      threadId: selectedThreadId
    });
    
    if (lastMessage) {
      lastMessageTimestampRef.current = lastMessage.created_at;
    }

    const POLLING_INTERVAL = 2000; // 2 seconds

    // Use a single interval instead of nested ones
    pollingIntervalRef.current = setInterval(async () => {
      try {
        await fetchNewMessages();
      } catch (error) {
        console.error('Polling error:', error);
        stopPolling();
      }
    }, POLLING_INTERVAL);

    // Cleanup on component unmount
    return () => stopPolling();
  }, [fetchNewMessages, messages, stopPolling, selectedThreadId]);

  const fetchMessages = useCallback(async (projectId, threadId) => {
    if (!threadId || !projectId) return;
    try {
      const response = await api.get(`/conversations/${projectId}/${threadId}/messages`, {
        params: {
          limit: LOAD_MESSAGES_LIMIT
        }
      });      
      
      const { messages: newMessages, has_more, run_status } = response.data;
      
      const sortedMessages = (newMessages || []).sort((a, b) => a.created_at - b.created_at);
      
      setMessages(sortedMessages);
      setHasMoreMessages(has_more);
      setConversationMetadata({ run_status });

      // Start polling if conversation is running in background mode
      if (run_status === 'background') {
        startPolling();
      } else if (['completed', 'failed', 'retriable'].includes(run_status)) {
        stopPolling();
      }
      
    } catch (error) {
      console.error('Error fetching messages:', error);
      handleHttpError(error, 'fetching messages');
    }
  }, [api, startPolling, stopPolling]);

  useEffect(() => {
    return () => {
      stopPolling();
    };
  }, [selectedThreadId, stopPolling]);

  const fetchThreadsAndMessages = useCallback(async (newPage = 0) => {
    if (!selectedProjectId) return;

    try {
        // First fetch threads
        const response = await api.get(`/conversations/${selectedProjectId}`, {
            params: {
                skip: newPage * LIMIT,
                limit: LIMIT
            }
        });

        const { threads, more_threads_available } = response.data;
        
        // Update threads state based on page
        setThreads(prevThreads => (newPage === 0 ? threads : [...prevThreads, ...threads]));
        setMoreThreadsAvailable(more_threads_available);
        setPage(newPage);

        // If we have threads and it's the first page, load messages for the latest thread
        if (threads.length > 0 && newPage === 0) {
            const latestThreadId = threads[0].id;
            setSelectedThreadId(latestThreadId);
            
            // Fetch messages for the selected thread
            await fetchMessages(selectedProjectId, latestThreadId);
        } else if (threads.length === 0) {
            // No threads available
            setSelectedThreadId(null);
            setMessages([]);
            setHasMoreMessages(false);
        }
    } catch (error) {
        console.error('Error fetching threads and messages:', error);
        handleHttpError(error, 'fetching threads and messages');
    }
}, [selectedProjectId, api, LIMIT, fetchMessages]);

  
  const loadMoreThreads = useCallback(async () => {
    if (!selectedProjectId || !moreThreadsAvailable) return;

    await fetchThreadsAndMessages(page + 1);
  }, [selectedProjectId, moreThreadsAvailable, page, fetchThreadsAndMessages]);

  const createThread = useCallback(async () => {
    if (!selectedProjectId) return;

    try {
      const response = await api.post(`/conversations/${selectedProjectId}/create`);
      const newThreadId = response.data.thread_id;
      
      // Set the thread ID immediately
      setSelectedThreadId(newThreadId);
      
      // Add the new thread to the threads list instead of fetching all threads
      setThreads(prevThreads => [{
        id: newThreadId,
        title: 'Untitled Conversation',
        created_at: Date.now() / 1000
      }, ...prevThreads]);
      
      // Clear messages for new thread
      setMessages([]);
      
      return newThreadId;
    } catch (error) {
      handleHttpError(error, 'creating new conversation');
      return null;
    }
  }, [api, selectedProjectId]);

  const deleteThread = useCallback(async (threadId) => {
    if (!selectedProjectId) return;

    try {
      setIsDeletingThread(true);
      await api.delete(`/conversations/${selectedProjectId}/${threadId}`);

      setThreads(prevThreads => prevThreads.filter(thread => thread.id !== threadId));

      if (selectedThreadId === threadId) {
        if (threads.length > 1) {
          const newSelectedThreadId = threads.find(thread => thread.id !== threadId).id;
          setSelectedThreadId(newSelectedThreadId);
          handleThreadSelect(newSelectedThreadId);
        } else {
          setSelectedThreadId(null);
          setMessages([]);
          setActiveAgents([]);
        }
      }
    } catch (error) {
      handleHttpError(error, 'deleting conversation');
    } finally {
      setIsDeletingThread(false);
    }
  }, [api, selectedProjectId, selectedThreadId, threads]);

  const fetchAgentsForThread = useCallback(async (threadId) => {
    if (!threadId || !selectedProjectId) return;

    try {
      let response = await api.get(`/conversations/${selectedProjectId}/${threadId}/agents`);
      let agents = response.data.agents;
      setActiveAgents(agents);
    } catch (error) {
      if (error.response?.status === 404) {
        setActiveAgents([]);
        return;
      }
      console.error('Error fetching agents for conversation:', error);
    }
  }, [api, selectedProjectId]);

  const handleThreadSelect = useCallback(async (threadId) => {
    if (threadId === selectedThreadId || !selectedProjectId) return;

    try {
      setIsLoadingConversation(true);
      setSelectedThreadId(threadId);

      try {
        await fetchMessages(selectedProjectId, threadId);
      } catch (error) {
        console.error('Error fetching messages:', error);
        if (error.response?.status === 404) {
          setSelectedThreadId(null);
          setActiveAgents([]);
          localStorage.removeItem('lastSelectedThread');
          setIsLoadingConversation(false);
          return;
        }
        throw error;
      }

      try {
        await Promise.all([
          fetchDataSources(selectedProjectId, threadId, 'conversation'),
          fetchAgentsForThread(threadId)
        ]);
      } catch (error) {
        console.error('Error fetching conversation data:', error);
      }

    } catch (error) {
      console.error('Error selecting conversation:', error);
      setSelectedThreadId(null);
      localStorage.removeItem('lastSelectedThread');
    } finally {
      setIsLoadingConversation(false);
    }
  }, [api, selectedProjectId, selectedThreadId, fetchDataSources, fetchAgentsForThread]);

  const loadMoreMessages = useCallback(async () => {
    if (!selectedProjectId || !selectedThreadId || !hasMoreMessages) {
        return false;
    }

    try {
        // Get the oldest visible message's timestamp
        const oldestMessage = messages[0];  // First message is oldest since we're maintaining chronological order
        if (!oldestMessage) {
            setHasMoreMessages(false);
            return false;
        }

        const oldestTimestamp = oldestMessage.created_at;
        
        const response = await api.get(`/conversations/${selectedProjectId}/${selectedThreadId}/messages`, {
            params: {
                before: oldestTimestamp,
                limit: LOAD_MESSAGES_LIMIT,
            },
        });

        const { messages: newMessages, has_more } = response.data;
        
        if (!newMessages || newMessages.length === 0) {
            setHasMoreMessages(false);
            return false;
        }
        
        // Sort new messages chronologically and add them to the beginning
        const sortedNewMessages = newMessages.sort((a, b) => a.created_at - b.created_at);
        setMessages(prevMessages => [...sortedNewMessages, ...prevMessages]);
        setHasMoreMessages(has_more);
        return has_more;
    } catch (error) {
        console.error('Error loading more messages:', error);
        handleHttpError(error, 'loading more messages');
        return false;
    }
}, [api, selectedProjectId, selectedThreadId, messages, hasMoreMessages]);

  const handleGetConversationData = useCallback(async () => {
    if (!selectedThreadId || !selectedProjectId) {
      handleHttpError(new Error('No conversation selected'), 'fetching conversation data');
    }
    
    setIsLoadingDataSources(true);
    try {
      const conversationData = await fetchDataSources(selectedProjectId, selectedThreadId, 'conversation');
      setConversationData(conversationData);
      return conversationData;
    } catch (error) {
      handleHttpError(error, 'fetching conversation data');
    } finally {
      setIsLoadingDataSources(false);
    }
  }, [selectedThreadId, selectedProjectId, fetchDataSources]);

  const handleAddConversationDataSource = async (dataSourceRequest) => {
    if (!selectedThreadId || !selectedProjectId) {
      console.error('No conversation selected');
      return;
    }

    try {
      setIsUpdatingConversationDataSource(true);
      const result = await addDataSource(selectedProjectId, selectedThreadId, "conversation", dataSourceRequest);
      if (result) {
        setConversationData(prevData => ({
          ...prevData,
          data_sources: [...(prevData.data_sources || []), result]
        }));
        return result;
      }
      return null;
    } catch (error) {
      handleHttpError(error, 'adding data source to conversation');
    } finally {
      setIsUpdatingConversationDataSource(false);
    }
  };

  const handleRemoveConversationDataSource = async (dataSourceId) => {
    if (!selectedThreadId || !selectedProjectId) {
      console.error('No conversation selected');
      return;
    }

    try {
      await removeDataSource(selectedProjectId, selectedThreadId, 'conversation', dataSourceId);
      setConversationData(prevData => ({
        ...prevData,
        data_sources: prevData.data_sources.filter(source => source.id !== dataSourceId)
      }));
    } catch (error) {
      console.error('Error removing data source:', error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchDataSourcesEffect = async () => {
      if (selectedThreadId && selectedProjectId) {
        try {
          await handleGetConversationData();
        } catch (error) {
          console.error('Error fetching data sources:', error);
        }
      } else {
        setConversationData([]);
      }
    };

    fetchDataSourcesEffect();
  }, [selectedThreadId, selectedProjectId]);

  const renameThread = useCallback(async (threadId, newTitle) => {
    if (!selectedProjectId || !threadId) return;

    try {
      const response = await api.put(`/conversations/${selectedProjectId}/${threadId}/rename`, { new_title: newTitle });
      if (response.data && response.data.new_title) {
        setThreads(prevThreads => prevThreads.map(thread => 
          thread.id === threadId ? { ...thread, title: response.data.new_title } : thread
        ));
        if (threadId === selectedThreadId) {
          setCurrentThreadTitle(response.data.new_title);
        }
      }
    } catch (error) {
      handleHttpError(error, 'renaming conversation');
    }
  }, [api, selectedProjectId, selectedThreadId]);

  useEffect(() => {
    const initializeConversation = async () => {
      if (selectedProjectId) {
        setIsLoadingThreads(true);
        setIsLoadingConversation(true);
        try {
          await fetchThreadsAndMessages(0);
        } catch (error) {
          console.error('Error initializing conversation:', error);
        } finally {
          setTimeout(() => {
            setIsLoadingThreads(false);
            setIsLoadingConversation(false);
          }, 500);
        }
      } else {
        setThreads([]);
        setSelectedThreadId(null);
        setMessages([]);
        setActiveAgents([]);
        setPage(0);
        setIsLoadingThreads(false);
      }
    };

    initializeConversation();
  }, [selectedProjectId]);

  const handleAudioChat = useCallback(async (audioBlob) => {
    if (!selectedThreadId || !selectedProjectId) {
      console.error('No conversation or project selected');
      return [];
    }

    const formData = new FormData();
    formData.append('audio_file', audioBlob, 'audio.webm');  // Changed file extension to .webm
    formData.append('conversation_id', selectedThreadId);
    formData.append('max_rounds', '1');
    formData.append('message', JSON.stringify({ role: 'user', content: '' }));

    try {
      setIsLoadingConversation(true);
      const response = await api.post(
        `/conversations/${selectedProjectId}/audio-chat`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          responseType: 'text',
        }
      );

      console.log('Audio chat response received');

      const messages = [];
      const lines = response.data.split('\n');
      lines.forEach(line => {
        if (line.startsWith('data: ')) {
          try {
            const jsonData = JSON.parse(line.slice(5));
            messages.push(jsonData);
            setMessages(prevMessages => [...prevMessages, jsonData]);
          } catch (error) {
            console.error('Error parsing JSON:', error);
          }
        }
      });

      return messages;
    } catch (error) {
      console.error('Error in handleAudioChat:', error);
      handleHttpError(error, 'processing audio chat');
      return [];
    } finally {
      setIsLoadingConversation(false);
    }
  }, [api, selectedProjectId, selectedThreadId]);

  // Add this useEffect to fetch threads when component mounts with a lastSelectedProject
  useEffect(() => {
    const initializeConversations = async () => {
      const lastSelectedProject = localStorage.getItem('lastSelectedProject');
      if (lastSelectedProject) {
        try {
          const response = await api.get(`/conversations/${lastSelectedProject}`, {
            params: {
              skip: 0,
              limit: LIMIT
            }
          });
          
          const { threads, more_threads_available } = response.data;
          setThreads(threads);
          setMoreThreadsAvailable(more_threads_available);
        } catch (error) {
          console.error('Error fetching initial conversations:', error);
        }
      }
    };

    initializeConversations();
  }, []); // Run only once on mount

  const cancelRun = useCallback(async () => {
    if (!selectedProjectId || !selectedThreadId) return;

    try {
        await api.post(`/conversations/${selectedProjectId}/${selectedThreadId}/cancel-run`);
        stopPolling();
        // Update conversation metadata to reflect cancelled state
        setConversationMetadata({ run_status: 'completed' });
    } catch (error) {
        console.error('Error cancelling run:', error);
        handleHttpError(error, 'cancelling run');
    }
  }, [api, selectedProjectId, selectedThreadId, stopPolling, setConversationMetadata]);

  return {
    threads,
    selectedThreadId,
    setSelectedThreadId,
    currentThreadTitle,
    setCurrentThreadTitle,
    moreThreadsAvailable,
    loadMoreThreads,
    createThread,
    deleteThread,
    renameThread,
    messages,
    setMessages,
    handleThreadSelect,
    handleGetConversationData,
    handleAddConversationDataSource,
    handleRemoveConversationDataSource,
    fetchAgentsForThread,
    activeAgents,
    setActiveAgents,
    conversationData,
    isUpdatingConversationDataSource,
    isLoadingConversation,
    loadMoreMessages,
    hasMoreMessages,
    handleAudioChat,
    isDeletingThread,
    isLoadingThreads,
    isPollingEnabled,
    startPolling,
    stopPolling,
    runStatus: conversationMetadata?.run_status,
    setConversationMetadata,
    cancelRun,
    expandedMessages,
    toggleMessageExpansion,
  };
};

export default useConversations;
