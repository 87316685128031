import React from 'react';
import { BarChart3 } from 'lucide-react';
import useTokens from '../../hooks/useTokens';

const TokenDisplay = ({ onShowDetails }) => {
    const { usage, formatNumber } = useTokens();

    if (!usage || !usage.limits) {
        return (
            <div className="flex flex-col items-end text-[11px] text-gray-400">
                <div className="flex items-center gap-2">
                    <span className="text-[10px] text-gray-500 mt-0.5">
                        Loading usage...
                    </span>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col items-end text-[11px] text-gray-400">
            <div className="flex items-center gap-2">
                <span className="text-[10px] text-gray-500 mt-0.5">
                    {formatNumber(usage.limits.remaining_requests)} requests remaining
                </span>
                <button onClick={onShowDetails}>
                    <BarChart3 size={14} />
                </button>
            </div>
        </div>
    );
};

export default TokenDisplay; 