import React, { useState, useEffect } from 'react';
import { UserPlus, UserMinus, Edit, Loader } from 'lucide-react';
import ErrorPopup from './ErrorPopup';
import AgentEditDialog from './AgentEditDialog';

const Agents = ({
  activeAgents,
  availableAgents,
  agentData,
  addAgentToConversation,
  updateAgent,
  deleteAgent,
  resetDefaultAgent,
  removeAgentFromConversation,
  createAgent,
  getAgentData,
  addDataSource,
  removeDataSource,
  refreshAgents,
  getAgentWithVersions,
  isExpanded,
  isAddingAgent,
  setIsAddingAgent,
  models,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [editingAgent, setEditingAgent] = useState(null);
  const [error, setError] = useState(null);

  // Sort agents by creation/update time
  const sortAgents = (agents) => {
    return [...agents].sort((a, b) => {
      // Use updated_at if available, fall back to created_at
      const aTime = a.updated_at || a.created_at;
      const bTime = b.updated_at || b.created_at;
      return bTime - aTime; // Sort in descending order (newest first)
    });
  };

  // Filter and sort available agents
  const filteredAvailableAgents = sortAgents(
    availableAgents
      .filter(agent => !activeAgents.some(activeAgent => activeAgent.id === agent.id))
      .filter(agent => 
        searchTerm === '' || 
        agent.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        agent.description?.toLowerCase().includes(searchTerm.toLowerCase())
      )
  );

  // Sort active agents
  const sortedActiveAgents = sortAgents(activeAgents);

  useEffect(() => {
    if (isExpanded) {
      const refreshAgentsWithError = async () => {
        try {
          await refreshAgents();
        } catch (error) {
          setError(error.message);
        }
      };
      refreshAgentsWithError();
    }
  }, [isExpanded, refreshAgents]);

  const handleAddAgent = async (agentId) => {
    try {
      setIsAddingAgent(true);
      await addAgentToConversation(agentId);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsAddingAgent(false);
    }
  };

  const handleEditAgent = async (agent) => {
    if (agent.id) {
      // Fetch versions when opening existing agent
      await getAgentWithVersions(agent.id);
    }
    setEditingAgent(agent);
  };

  return (
    <div className="agent-panel space-y-2 bg-gray-800/50 rounded-md shadow-lg p-2">
      <div className="space-y-2">
        {/* Active Agents Section */}
        {sortedActiveAgents.length > 0 && (
          <div className="mb-4">
            <h6 className="text-sm mb-2 text-gray-200">In Conversation</h6>
            <div className="max-h-46 overflow-y-auto">
              <div className="space-y-2">
                {sortedActiveAgents.map((agent) => (
                  <div 
                    key={agent.id} 
                    className="group relative flex flex-col px-2 py-1.5 
                      bg-gray-400/40 rounded-md 
                      transition-all duration-200 
                      hover:bg-gray-400/60 
                      hover:shadow-lg hover:shadow-gray-500/5
                      hover:translate-x-0.5
                      cursor-pointer"
                    onDoubleClick={() => {
                      handleEditAgent(agent);
                    }}
                  >
                    <div className="flex-1 min-w-0">
                      <div className="text-sm font-medium text-gray-100 truncate">
                        {agent.name}
                        {agent.is_default && agent.is_modified && (
                          <span className="ml-1 text-xs text-indigo-400">(Modified)</span>
                        )}
                      </div>
                      <div className="text-xs text-gray-300 truncate">{agent.description}</div>
                    </div>
                    <div className="absolute right-2 top-2 flex items-center opacity-0 group-hover:opacity-100 transition-opacity gap-2">
                      <Edit 
                        size={16} 
                        className="text-gray-400 hover:text-blue-400 cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditAgent(agent);
                        }}
                      />
                      <UserMinus 
                        size={16} 
                        className="text-gray-400 hover:text-red-400 cursor-pointer"
                        onClick={() => removeAgentFromConversation(agent.id)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Available Agents Section */}
        <div>
          <h6 className="text-sm mb-2 text-gray-200">Available Agents</h6>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search available agents"
            className="w-full p-2 border border-gray-600 rounded-md text-sm mb-2 
              bg-gray-800/50 text-gray-200 
              focus:ring-2 focus:ring-blue-500/50 focus:border-transparent
              placeholder-gray-500
              transition-all duration-200
              hover:border-gray-500"
          />
          <div className="max-h-44 overflow-y-auto">
            <div className="space-y-2">
              {filteredAvailableAgents.map((agent) => (
                <div 
                  key={agent.id} 
                  className="group relative flex flex-col px-4 py-2 
                    bg-gray-700/80 hover:bg-gray-600/80 
                    rounded-md transition-all 
                    hover:shadow-lg hover:shadow-gray-600/5
                    hover:translate-x-0.5
                    cursor-pointer"
                  onDoubleClick={() => {
                    handleEditAgent(agent);
                  }}
                >
                  <div className="flex-1 min-w-0">
                    <div className="text-sm font-medium text-gray-200 truncate">{agent.name}</div>
                    <div className="text-xs text-gray-400 truncate">{agent.description}</div>
                  </div>
                  <div className="absolute right-2 top-2 flex items-center opacity-0 group-hover:opacity-100 transition-opacity gap-2">
                    <Edit 
                      size={16} 
                      className="text-gray-400 hover:text-blue-400 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditAgent(agent);
                      }}
                    />
                    <UserPlus 
                      size={16} 
                      className="text-gray-400 hover:text-blue-400 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAddAgent(agent.id);
                      }}
                    />
                  </div>
                </div>
              ))}
              {filteredAvailableAgents.length === 0 && (
                <div className="text-sm text-gray-500 p-2">
                  No agents found
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {editingAgent !== null && (
        <AgentEditDialog
          isOpen={true}
          onClose={() => setEditingAgent(null)}
          agent={editingAgent.id ? editingAgent : undefined}
          createAgent={createAgent}
          updateAgent={updateAgent}
          isNewAgent={!editingAgent.id}
          isLoading={false}
          deleteAgent={deleteAgent}
          getAgentWithVersions={getAgentWithVersions}
          agentData={agentData}
          getAgentData={getAgentData}
          addDataSource={addDataSource}
          removeDataSource={removeDataSource}
          resetAgent={resetDefaultAgent}
          models={models}
        />
      )}

      {error && (
        <ErrorPopup message={error} onClose={() => setError(null)} />
      )}
    </div>
  );
};

export default Agents;
