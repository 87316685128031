import React, { useRef, useMemo, useState, useEffect } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';

const DynamicNeuralNetwork = () => {
  const groupRef = useRef();
  const { viewport } = useThree();
  const [isDragging, setIsDragging] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const rotationVelocityRef = useRef({ x: 0, y: 0 });
  const lastMousePos = useRef({ x: 0, y: 0 });
  const lastUpdateTime = useRef(Date.now());
  const MAX_VELOCITY = 0.05;

  // Add touch and mouse interaction listeners
  useEffect(() => {
    const handleStart = (event) => {
      setIsDragging(true);
      const point = event.touches ? event.touches[0] : event;
      lastMousePos.current = {
        x: point.clientX,
        y: point.clientY
      };
      lastUpdateTime.current = Date.now();
    };

    const handleEnd = () => {
      setIsDragging(false);
    };

    const handleMove = (event) => {
      if (!isDragging || !groupRef.current) return;
      
      const point = event.touches ? event.touches[0] : event;
      const currentTime = Date.now();
      const deltaTime = Math.max((currentTime - lastUpdateTime.current) / 1000, 0.016);
      
      const deltaX = point.clientX - lastMousePos.current.x;
      const deltaY = point.clientY - lastMousePos.current.y;

      // Corrected rotation logic:
      // Moving mouse left/right should rotate around vertical axis (Y)
      // Moving mouse up/down should rotate around horizontal axis (X)
      // Invert deltaX for more natural feel (moving mouse right rotates right)
      groupRef.current.rotation.y -= (deltaX * 0.005); // Inverted for natural direction
      groupRef.current.rotation.x -= (deltaY * 0.005); // Inverted for natural direction

      // Update velocity for momentum with corrected directions
      rotationVelocityRef.current = {
        x: -deltaY * 0.005,
        y: -deltaX * 0.005
      };

      lastMousePos.current = {
        x: point.clientX,
        y: point.clientY
      };
      lastUpdateTime.current = currentTime;
    };

    // Add mouse event listeners
    window.addEventListener('mousedown', handleStart);
    window.addEventListener('mouseup', handleEnd);
    window.addEventListener('mousemove', handleMove);
    window.addEventListener('mouseleave', handleEnd);

    // Add touch event listeners
    window.addEventListener('touchstart', handleStart);
    window.addEventListener('touchend', handleEnd);
    window.addEventListener('touchmove', handleMove);
    window.addEventListener('touchcancel', handleEnd);

    return () => {
      // Remove mouse event listeners
      window.removeEventListener('mousedown', handleStart);
      window.removeEventListener('mouseup', handleEnd);
      window.removeEventListener('mousemove', handleMove);
      window.removeEventListener('mouseleave', handleEnd);

      // Remove touch event listeners
      window.removeEventListener('touchstart', handleStart);
      window.removeEventListener('touchend', handleEnd);
      window.removeEventListener('touchmove', handleMove);
      window.removeEventListener('touchcancel', handleEnd);
    };
  }, [isDragging]);

  // Generate nodes
  const nodes = useMemo(() => {
    const nodeCount = 75;
    const positions = [];
    const colors = [];
    
    for (let i = 0; i < nodeCount; i++) {
      // Create nodes in a spherical distribution
      const radius = 3;
      const theta = Math.random() * Math.PI * 2;
      const phi = Math.acos((Math.random() * 2) - 1);
      
      positions.push(
        radius * Math.sin(phi) * Math.cos(theta),
        radius * Math.sin(phi) * Math.sin(theta),
        radius * Math.cos(phi)
      );

      // Enhanced color gradient with more vibrant blues
      const intensity = 0.5 + Math.random() * 0.5;
      colors.push(
        0.2,  // Touch of red for contrast
        0.6 + Math.random() * 0.4,  // Varied green for cyan effect
        1.0   // Full blue
      );
    }

    return {
      positions: new Float32Array(positions),
      colors: new Float32Array(colors)
    };
  }, []);

  // Generate connections with more density and varied lengths
  const connections = useMemo(() => {
    const positions = [];
    const colors = [];
    const nodePositions = nodes.positions;
    const maxConnections = 150;
    
    for (let i = 0; i < maxConnections; i++) {
      const startIndex = Math.floor(Math.random() * (nodePositions.length / 3)) * 3;
      const nearbyIndex = (startIndex + Math.floor(Math.random() * 5) * 3) % nodePositions.length;
      
      positions.push(
        nodePositions[startIndex], nodePositions[startIndex + 1], nodePositions[startIndex + 2],
        nodePositions[nearbyIndex], nodePositions[nearbyIndex + 1], nodePositions[nearbyIndex + 2]
      );

      // Enhanced connection colors with gradient effect
      const intensity = 0.5 + Math.random() * 0.5;
      colors.push(
        0.0, 0.9, 1.0,  // Start: Bright cyan
        0.0, 0.4, 1.0   // End: Deep blue
      );
    }

    return {
      positions: new Float32Array(positions),
      colors: new Float32Array(colors)
    };
  }, [nodes]);

  // Create geometries
  const nodeGeometry = useMemo(() => {
    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', new THREE.BufferAttribute(nodes.positions, 3));
    geometry.setAttribute('color', new THREE.BufferAttribute(nodes.colors, 3));
    return geometry;
  }, [nodes]);

  const connectionGeometry = useMemo(() => {
    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', new THREE.BufferAttribute(connections.positions, 3));
    geometry.setAttribute('color', new THREE.BufferAttribute(connections.colors, 3));
    return geometry;
  }, [connections]);

  // Modified animation frame with more stable rotation handling
  useFrame(({ clock }) => {
    if (!groupRef.current) return;

    const time = clock.getElapsedTime() * 2;
    
    if (isDragging) {
      // Direct rotation based on drag velocity
      groupRef.current.rotation.x += rotationVelocityRef.current.x;
      groupRef.current.rotation.y += rotationVelocityRef.current.y;
    } else {
      // Apply momentum with decay when not dragging
      rotationVelocityRef.current = {
        x: rotationVelocityRef.current.x * 0.95,
        y: rotationVelocityRef.current.y * 0.95
      };
      
      groupRef.current.rotation.x += rotationVelocityRef.current.x;
      groupRef.current.rotation.y += rotationVelocityRef.current.y;

      // Add subtle autonomous rotation when nearly still
      if (Math.abs(rotationVelocityRef.current.x) < 0.001 && 
          Math.abs(rotationVelocityRef.current.y) < 0.001) {
        groupRef.current.rotation.y += isHovering ? 0.002 : 0.001; // Faster rotation on hover
        groupRef.current.rotation.z = Math.sin(time * 0.025) * (isHovering ? 0.15 : 0.1); // Larger oscillation on hover
      }
    }
    
    // Ensure rotation values stay within reasonable bounds
    groupRef.current.rotation.x = groupRef.current.rotation.x % (Math.PI * 2);
    groupRef.current.rotation.y = groupRef.current.rotation.y % (Math.PI * 2);
    groupRef.current.rotation.z = groupRef.current.rotation.z % (Math.PI * 2);
    
    // Enhanced breathing effect with hover
    const baseScale = isHovering ? 1.05 : 1; // Slightly larger when hovering
    const breathingIntensity = isHovering ? 0.04 : 0.03;
    const scale = baseScale + Math.sin(time * 0.5) * breathingIntensity;
    groupRef.current.scale.set(scale, scale, scale);
  });

  return (
    <group 
      ref={groupRef}
      onPointerEnter={() => setIsHovering(true)}
      onPointerLeave={() => setIsHovering(false)}
    >
      {/* Enhanced glow sphere for depth */}
      <mesh>
        <sphereGeometry args={[2.9, 16, 16]} />
        <meshBasicMaterial
          color="#0055FF"
          transparent
          opacity={0.03}
          blending={THREE.AdditiveBlending}
          depthWrite={false}
        />
      </mesh>

      {/* Outer glow with hover effect */}
      <mesh>
        <sphereGeometry args={[3.1, 32, 32]} />
        <meshBasicMaterial
          color="#00FFFF"
          transparent
          opacity={isHovering ? 0.02 : 0.01}
          blending={THREE.AdditiveBlending}
          depthWrite={false}
        />
      </mesh>

      {/* Connections */}
      <lineSegments geometry={connectionGeometry}>
        <lineBasicMaterial
          vertexColors
          transparent
          opacity={0.4}
          blending={THREE.AdditiveBlending}
          depthWrite={false}
          linewidth={1}
        />
      </lineSegments>

      {/* Nodes with enhanced glow */}
      <points geometry={nodeGeometry}>
        <pointsMaterial
          size={0.2}
          vertexColors
          transparent
          opacity={0.8}
          sizeAttenuation={true}
          depthWrite={false}
          blending={THREE.AdditiveBlending}
        />
      </points>

      {/* Additional central glow for emphasis */}
      <mesh>
        <sphereGeometry args={[2.5, 32, 32]} />
        <meshBasicMaterial
          color="#4488FF"
          transparent
          opacity={0.03}
          blending={THREE.AdditiveBlending}
          depthWrite={false}
        />
      </mesh>
    </group>
  );
};

export default DynamicNeuralNetwork; 