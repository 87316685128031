import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const StatusDialog = ({ isOpen, onClose, status, email }) => {
  if (!isOpen) return null;

  const messages = {
    pending: {
      title: "You're on the waitlist!",
      message: "Thanks for your interest. We'll notify you by email when your account is approved.",
      buttonText: "Got it"
    },
    not_found: {
      title: "Join our waitlist",
      message: "You haven't joined our waitlist yet. Would you like to join now?",
      buttonText: "Join waitlist"
    },
    error: {
      title: "Oops!",
      message: "Something went wrong. Please try again.",
      buttonText: "Close"
    }
  };

  const currentStatus = messages[status] || messages.error;

  return (
    <AnimatePresence>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className="relative transform overflow-hidden rounded-lg bg-gray-800 border border-blue-500/20 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
          >
            <div>
              <div className="mt-3 text-center sm:mt-5">
                <h3 className="text-lg font-medium leading-6 text-blue-200">
                  {currentStatus.title}
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-300">
                    {currentStatus.message}
                  </p>
                  {email && (
                    <p className="mt-2 text-sm text-blue-300/70">
                      {email}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-blue-500/20 px-3 py-2 text-sm font-semibold text-blue-300 shadow-sm hover:bg-blue-500/30 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                onClick={onClose}
              >
                {currentStatus.buttonText}
              </button>
            </div>
          </motion.div>
        </div>
      </div>
      <div className="fixed inset-0 bg-black/70 backdrop-blur-sm z-40" />
    </AnimatePresence>
  );
};

export default StatusDialog; 