import React from 'react';
import { motion } from 'framer-motion';

const CloseIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="16" 
    height="16" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"
  >
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
  </svg>
);

const CookieConsent = ({ isOpen, onAccept, setShowTerms }) => {
  if (!isOpen) return null;

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="fixed sm:bottom-20 sm:right-4 bottom-0 right-0 w-full sm:w-auto sm:max-w-[320px] z-50"
    >
      <div className="bg-gray-800/90 backdrop-blur-sm sm:rounded-lg shadow-xl p-3 sm:border border-blue-500/20 relative">
        <button
          onClick={onAccept}
          className="absolute top-2 right-2 text-gray-400 hover:text-gray-300 
            transition-colors duration-300"
        >
          <CloseIcon />
        </button>

        <p className="text-sm text-gray-300 pr-4 leading-relaxed">
          We use cookies to enhance your experience. By continuing, you agree to our{' '}
          <button
            className="text-blue-400 hover:text-blue-300 underline"
            onClick={() => setShowTerms(true)}
          >
            Terms
          </button>
          .
        </p>
      </div>
    </motion.div>
  );
};

export default CookieConsent;
