import { useState, useEffect } from 'react';
import useApi from './useApi';
import { handleHttpError } from '../utils/errorHandling';

const useArtifacts = (selectedArtifactId, projectId, threadId) => {
  const [currentArtifact, setCurrentArtifact] = useState(null);
  const [navigation, setNavigation] = useState({ previous: null, next: null });
  const [isLoading, setIsLoading] = useState(false);
  const { api } = useApi();

  useEffect(() => {
    const fetchArtifact = async () => {
      if (!selectedArtifactId) {
        setCurrentArtifact(null);
        setNavigation({ previous: null, next: null });
        return;
      }

      setIsLoading(true);
      try {
        // Fetch artifact with versions
        const response = await api.get(`/conversations/${projectId}/${threadId}/artifacts/${selectedArtifactId}`);
        const artifact = response.data;

        // Set active version as selected version
        const activeVersion = artifact.versions.find(v => v.is_active) || artifact.versions[0];
        setCurrentArtifact({
          ...artifact,
          active_version: activeVersion,
          selectedVersion: activeVersion
        });

        // Fetch navigation
        const navResponse = await api.get(`/conversations/${projectId}/${threadId}/artifacts/${selectedArtifactId}/navigation`);
        
        // Map the response properties to match what the component expects
        setNavigation({
          previous: navResponse.data.previous_id,
          next: navResponse.data.next_id
        });
      } catch (error) {
        console.error('Error fetching artifact:', error);
        setNavigation({ previous: null, next: null });
      } finally {
        setIsLoading(false);
      }
    };

    fetchArtifact();
  }, [selectedArtifactId, projectId, threadId, api]);

  const handlePrevious = () => {
    if (navigation.previous) {
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.set('artifact', navigation.previous);
      window.history.pushState({ artifactId: navigation.previous }, '', newUrl.toString());
      
      window.dispatchEvent(new CustomEvent('artifactNavigate', {
        detail: { artifactId: navigation.previous }
      }));
    }
  };

  const handleNext = () => {
    if (navigation.next) {
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.set('artifact', navigation.next);
      window.history.pushState({ artifactId: navigation.next }, '', newUrl.toString());
      
      window.dispatchEvent(new CustomEvent('artifactNavigate', {
        detail: { artifactId: navigation.next }
      }));
    }
  };

  return {
    currentArtifact,
    navigation,
    handlePrevious,
    handleNext,
    isLoading
  };
};

export default useArtifacts;