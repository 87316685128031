import React, { useState, useEffect, useRef } from 'react';
import ProjectEditDialog from './ProjectEditDialog';
import ErrorPopup from './ErrorPopup';
import AgentEditDialog from './AgentEditDialog';
import CarouselRow from './CarouselRow';
import ShareProjectDialog from './ShareProjectDialog';
import { User, FolderPlus, UserPlus, Filter, ChevronUp, ChevronDown, Home, MessageSquare, Loader2 } from 'lucide-react';
import ConfirmationDialog from './ConfirmationDialog';
import FilterBar from './FilterBar';
import RecentConversations from './RecentConversations';
import ProjectDropdown from './ProjectDropdown';
import Button from './ui/Button';
import UserManager from './UserManager';

const Dashboard = ({ 
  onSelectProject, 
  projects, 
  threads, 
  selectedProjectId,
  user,
  handleLogOut,
  createProject,
  updateProject,
  deleteProject,
  agents,
  agentVersions,
  setShowDashboard,
  showDashboard,
  createAgent,
  updateAgent,
  deleteAgent,
  addDataSource,
  removeDataSource,
  resetAgent,
  agentData,
  shareProject,
  setIsSidebarCollapsed,
  deleteThread,
  isDeletingThread,
  isLoadingThreads,
  isSmallScreen,
  getAgentWithVersions,
  getAgentData,
  isAdmin,
  api,
  showUserManager,
  setShowUserManager,
  createNewThread,
  isCreatingNewThread,
  handleConversationSelect,
  models,
}) => {

  const [error, setError] = useState(null);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [isCreatingProject, setIsCreatingProject] = useState(false);
  const [editingProject, setEditingProject] = useState(null);
  const [editingAgent, setEditingAgent] = useState(null);
  const [sharingProject, setSharingProject] = useState(null);
  const [showLogout, setShowLogout] = useState(false);
  const [threadToDelete, setThreadToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showShared, setShowShared] = useState(true);
  const [searchAgents, setSearchAgents] = useState('');
  const [showDefaultAgents, setShowDefaultAgents] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [showAgentFilters, setShowAgentFilters] = useState(false);
  const [showAgentsSection, setShowAgentsSection] = useState(!isSmallScreen);
  const [showProjectsSection, setShowProjectsSection] = useState(true);
  const [isCreatingAgent, setIsCreatingAgent] = useState(false);
  const [showConversationFilters, setShowConversationFilters] = useState(false);

  // Update threads when project is selected
  const handleProjectSelect = async (projectId) => {
    try {
      await onSelectProject(projectId);
    } catch (error) {
      console.error('Error selecting project:', error);
    }
  };

  // Show threads directly from the threads prop
  const recentThreads = threads || [];  // Show all conversations

  const handleCreateProject = async (projectData) => {
    setIsCreatingProject(true);
    try {
      const result = await createProject(projectData);
      if (result?.project) {
        handleProjectSelect(result.project.id);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsCreatingProject(false);
    }
  };

  const handleUpdateProject = async (projectId, updatedData) => {
    try {
      await updateProject(projectId, updatedData);
      setEditingProject(null);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleDeleteProject = async (projectId) => {
    try {
      await deleteProject(projectId);
      setEditingProject(null);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleEditAgent = (agent) => {
    setEditingAgent(agent);
  };

  // Update the share handler
  const handleShareProject = async (project, email) => {
    try {
      const projectId = project.id;
      await shareProject(projectId, email);
    } catch (error) {
      setError(error.message);
    }
  };

  // Rename this to be more specific
  const handleEditProjectClick = (project) => {
    setEditingProject(project);
  };

  // Add click handler for the dashboard container
  const handleDashboardClick = () => {
    setShowLogout(false);
  };

  // Sort projects by creation date (newest first)
  const sortedProjects = [...(projects || [])].sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });

  // Sort agents by creation date (newest first)
  const sortedAgents = [...(agents || [])].sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });

  const handleDeleteThreadClick = (threadId) => {
    setThreadToDelete(threadId);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteThread(threadToDelete);
    } catch (error) {
      setError(error.message);
    } finally {
      setThreadToDelete(null);
    }
  };

  const filteredProjects = sortedProjects
    .filter(project => showShared || !project.is_shared)
    .filter(project => 
      project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (project.objective || '').toLowerCase().includes(searchTerm.toLowerCase())
    );

  const filteredAgents = sortedAgents
    .filter(agent => 
      showDefaultAgents || !agent.is_default
    )
    .filter(agent =>
      agent.name.toLowerCase().includes(searchAgents.toLowerCase()) ||
      (agent.description || '').toLowerCase().includes(searchAgents.toLowerCase())
    );

  // Add this helper function to get selected project name
  const getSelectedProjectName = () => {
    return projects.find(p => p._id === selectedProjectId)?.name || '';
  };

  const handleNewConversation = async () => {
    try {
      if (!selectedProjectId) {
        setError('Please select a project first');
        return;
      }
      
      await createNewThread();
      setShowDashboard(false);
    } catch (error) {
      setError(error.message);
    }
  };

  // Update the section container class to remove padding (we'll handle it in CarouselRow)
  const sectionContainerClass = "max-w-[1200px] mx-auto w-full";

  const handleProjectDoubleClick = async (project) => {
    try {
      // First select the project
      await handleProjectSelect(project.id);
      
      // Find the most recent thread for this project
      const projectThreads = recentThreads.filter(thread => thread.project_id === project.id);
      if (projectThreads.length > 0) {
        // Sort by created_at (Unix timestamp) - no need to convert to Date object
        const mostRecentThread = projectThreads.sort((a, b) => 
          b.created_at - a.created_at  // Direct comparison of Unix timestamps
        )[0];
        
        // Navigate to the conversation
        handleConversationSelect(mostRecentThread);
        setShowDashboard(false);
      } else {
        // If no threads exist, create a new one
        await handleNewConversation();
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className={`flex-1 flex flex-col overflow-y-auto bg-gray-900 text-white ${isSmallScreen ? '' : 'rounded-2xl'}`}>
      {isAdmin && showUserManager ? (
        <div className="flex-1 p-4 overflow-auto">
          <UserManager 
            api={api} 
            setShowUserManager={setShowUserManager}
          />
        </div>
      ) : (
        <div className={`${isSmallScreen ? 'px-3 pt-8 bg-gray-700' : 'px-6'} min-h-full pt-4 relative pb-16`}>
          <div className={`${isSmallScreen ? 'w-full' : 'max-w-4xl w-full mx-auto'}`}>
            <div className="text-center mb-6">
              <p className="text-lg text-gray-300">
                Welcome back, {user?.name?.split(' ')[0] || user?.name || 'there'}
              </p>
            </div>
            {isSmallScreen ? (
              // Mobile Layout
              <div>
                {/* Projects Section */}
                <div className={`${sectionContainerClass} mt-24`}>
                  <div className="flex items-center justify-center gap-3">
                    <ProjectDropdown 
                      projects={filteredProjects}
                      selectedProjectId={selectedProjectId}
                      onSelect={handleProjectSelect}
                      onEdit={handleEditProjectClick}
                      isSmallScreen={isSmallScreen}
                    />
                  </div>
                </div>

                {/* Recent Conversations Section */}
                <div className={sectionContainerClass}>
                  <RecentConversations
                    conversations={recentThreads.map(thread => ({
                      ...thread,
                      projectId: selectedProjectId
                    }))}
                    onSelect={handleConversationSelect}
                    onDelete={handleDeleteThreadClick}
                    isDeletingThread={isDeletingThread}
                    isSmallScreen={isSmallScreen}
                    handleNewConversation={handleNewConversation}
                    isCreatingNewThread={isCreatingNewThread}
                    isLoadingConversations={isLoadingThreads}
                    showFilters={showConversationFilters}
                    setShowFilters={setShowConversationFilters}
                  />
                </div>

                {/* Agents Section */}
                {selectedProjectId && showAgentsSection && (
                  <div className={`${sectionContainerClass} mt-6`}>
                    <CarouselRow
                      items={filteredAgents}
                      onItemSelect={handleEditAgent}
                      selectedId={null}
                      onEditItem={handleEditAgent}
                      itemType="agent"
                      getAgentWithVersions={getAgentWithVersions}
                      getAgentData={getAgentData}
                      onCreateNew={() => setEditingAgent({})}
                      isCreating={isCreatingAgent}
                      onFilterClick={() => setShowAgentFilters(!showAgentFilters)}
                      showFilters={showAgentFilters}
                      showShared={showDefaultAgents}
                      setShowShared={setShowDefaultAgents}
                      searchTerm={searchAgents}
                      setSearchTerm={setSearchAgents}
                      onDoubleClick={handleProjectDoubleClick}
                      models={models}
                      addDataSource={addDataSource}
                      removeDataSource={removeDataSource}
                      agentData={agentData}
                    />
                  </div>
                )}
              </div>
            ) : (
              // Desktop Layout
              <div className="bg-gray-700 rounded-2xl p-8 mx-auto">
                {/* Projects Section */}
                <div className={sectionContainerClass}>
                  <CarouselRow
                    items={filteredProjects}
                    onItemSelect={onSelectProject}
                    selectedId={selectedProjectId}
                    onEditItem={handleEditProjectClick}
                    onShareItem={handleShareProject}
                    onDeleteItem={handleDeleteProject}
                    itemType="project"
                    onCreateNew={() => setEditingProject({})}
                    isCreating={isCreatingProject}
                    onFilterClick={() => setShowFilters(!showFilters)}
                    showFilters={showFilters}
                    showShared={showShared}
                    setShowShared={setShowShared}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    onDoubleClick={handleProjectDoubleClick}
                  />
                </div>

                {/* Recent Conversations Section */}
                <div className={`${sectionContainerClass} mt-8`}>
                  <RecentConversations
                    conversations={recentThreads.map(thread => ({
                      ...thread,
                      projectId: selectedProjectId
                    }))}
                    onSelect={handleConversationSelect}
                    onDelete={handleDeleteThreadClick}
                    isDeletingThread={isDeletingThread}
                    isSmallScreen={isSmallScreen}
                    handleNewConversation={handleNewConversation}
                    isCreatingNewThread={isCreatingNewThread}
                    isLoadingConversations={isLoadingThreads}
                    showFilters={showConversationFilters}
                    setShowFilters={setShowConversationFilters}
                  />
                </div>

                {/* Agents Section */}
                {selectedProjectId && showAgentsSection && (
                  <div className={`${sectionContainerClass} mt-8`}>
                    <CarouselRow
                      items={filteredAgents}
                      onItemSelect={handleEditAgent}
                      selectedId={null}
                      onEditItem={handleEditAgent}
                      itemType="agent"
                      getAgentWithVersions={getAgentWithVersions}
                      getAgentData={getAgentData}
                      onCreateNew={() => setEditingAgent({})}
                      isCreating={isCreatingAgent}
                      onFilterClick={() => setShowAgentFilters(!showAgentFilters)}
                      showFilters={showAgentFilters}
                      showShared={showDefaultAgents}
                      setShowShared={setShowDefaultAgents}
                      searchTerm={searchAgents}
                      setSearchTerm={setSearchAgents}
                      onDoubleClick={handleProjectDoubleClick}
                      models={models}
                      addDataSource={addDataSource}
                      removeDataSource={removeDataSource}
                      agentData={agentData}
                      deleteAgent={deleteAgent}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {/* AI Responses Disclaimer */}
      <div className="absolute bottom-4 left-0 right-0 text-center">
        <p className="text-[11px] sm:text-[12px] text-gray-400 max-w-2xl mx-auto px-4">
          AI responses may be inaccurate. Please verify important information.
        </p>
      </div>

      {error && (
        <ErrorPopup 
          message={error} 
          onClose={() => setError(null)} 
        />
      )}

      {threadToDelete && (
        <ConfirmationDialog
          title="Delete Conversation"
          message="Are you sure you want to delete this conversation? This action cannot be undone."
          onConfirm={handleConfirmDelete}
          onCancel={() => setThreadToDelete(null)}
          confirmText="Delete"
          isLoading={isDeletingThread}
        />
      )}

      {editingProject && (
        <ProjectEditDialog
          isOpen={!!editingProject}
          onClose={() => setEditingProject(null)}
          project={editingProject}
          updateProject={handleUpdateProject}
          deleteProject={handleDeleteProject}
          isNewProject={!editingProject.id}
          createProject={handleCreateProject}
          isLoading={isCreatingProject}
        />
      )}

      {editingAgent !== null && (
        <AgentEditDialog
          isOpen={true}
          onClose={() => setEditingAgent(null)}
          agent={editingAgent.id ? editingAgent : undefined}
          createAgent={createAgent}
          updateAgent={updateAgent}
          isNewAgent={!editingAgent.id}
          isLoading={isCreatingAgent}
          deleteAgent={deleteAgent}
          getAgentWithVersions={getAgentWithVersions}
          getAgentData={getAgentData}
          addDataSource={addDataSource}
          removeDataSource={removeDataSource}
          resetAgent={resetAgent}
          agentData={agentData}
          models={models}
        />
      )}
    </div>
  );
};

export default Dashboard;