import React from 'react';
import { 
  FileText, 
  Presentation, 
  AlertTriangle, 
  ClipboardList, 
  Microscope, 
  Image, 
  Briefcase,
  UserPlus,
  UserCog
} from 'lucide-react';

// Match ArtifactType enum from schemas.py
export const getArtifactIcon = (artifactType) => {
  switch (artifactType?.toLowerCase()) {
    case 'document':
      return <FileText size={14} />;
    case 'presentation':
      return <Presentation size={14} />;
    case 'error':
      return <AlertTriangle size={14} />;
    case 'plan':
      return <ClipboardList size={14} />;
    case 'evaluation':
      return <Microscope size={14} />;
    case 'image':
      return <Image size={14} />;
    case 'project':
      return <Briefcase size={14} />;
    case 'agent_creation':
      return <UserPlus size={14} />;
    case 'agent_update':
      return <UserCog size={14} />;
    default:
      return <FileText size={14} />;
  }
};

export const getArtifactColors = (artifactType) => {
  switch (artifactType?.toLowerCase()) {
    case 'document':
      return { bgColor: 'bg-blue-100', textColor: 'text-blue-800' };
    case 'presentation':
      return { bgColor: 'bg-indigo-100', textColor: 'text-indigo-800' };
    case 'error':
      return { bgColor: 'bg-red-100', textColor: 'text-red-800' };
    case 'plan':
      return { bgColor: 'bg-green-100', textColor: 'text-green-800' };
    case 'evaluation':
      return { bgColor: 'bg-purple-100', textColor: 'text-purple-800' };
    case 'image':
      return { bgColor: 'bg-pink-100', textColor: 'text-pink-800' };
    case 'project':
      return { bgColor: 'bg-yellow-100', textColor: 'text-yellow-800' };
    case 'agent_creation':
      return { bgColor: 'bg-cyan-100', textColor: 'text-cyan-800' };
    case 'agent_update':
      return { bgColor: 'bg-teal-100', textColor: 'text-teal-800' };
    default:
      return { bgColor: 'bg-gray-100', textColor: 'text-gray-800' };
  }
};

export const getArtifactTypeName = (artifactType) => {
  switch (artifactType?.toLowerCase()) {
    case 'document':
      return 'Document';
    case 'presentation':
      return 'Presentation';
    case 'error':
      return 'Error';
    case 'plan':
      return 'Plan';
    case 'evaluation':
      return 'Evaluation';
    case 'image':
      return 'Image';
    case 'project':
      return 'Project';
    case 'agent_creation':
      return 'Agent Creation';
    case 'agent_update':
      return 'Agent Update';
    default:
      return 'Artifact';
  }
};
