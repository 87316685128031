import React, { useRef, useEffect, useState, useCallback, forwardRef } from 'react';
import { debounce } from 'lodash';
import { Send, Database, Plus, X, Loader, Check, FileText, Link, Square, Mic, MicOff, Paperclip } from 'lucide-react';
import getCaretCoordinates from 'textarea-caret';
import ErrorPopup from './ErrorPopup';
import { StyledTextarea } from '../utils/styledComponents';
import useVoiceInput from '../hooks/useVoiceInput';
import DataSourceDialog from './DataSourceDialog';
import RunModeSelect from './ui/RunModeSelect';

// Add this function before the InputForm component
const isIPhone = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /iPhone/i.test(userAgent);
};

const InputForm = forwardRef(({
  inputValue,
  setInputValue,
  handleSendMessage,
  suggestions,
  setSuggestions,
  selectedAgents,
  setSelectedAgents,
  activeAgents,
  selectedThreadId,
  handleAddConversationDataSource,
  isMessageLoading,
  createThread,
  isCreatingNewThread,
  selectedProjectId,
  abortChat,
  messages,
  isAddingAgent,
  quickStart = false,
  onKeyPress,
  disabled,
  setIsSidebarCollapsed,
  setCollapseInternalMessages,
  isWaitingForInput,
  inputTimeout,
  runMode,
  runStatus,
  onRunModeChange,
  onCancel,
}, ref) => {
  const inputRef = useRef(null);
  const suggestionsRef = useRef(null);
  const [cursorPos, setCursorPos] = useState({ top: 0, left: 0 });
  const [confirmationDialog, setConfirmationDialog] = useState(null);
  const [error, setError] = useState(null);
  const [deletingDataSourceId, setDeletingDataSourceId] = useState(null);
  const [isIPhoneDevice, setIsIPhoneDevice] = useState(false);
  const isSmallScreen = window.innerWidth < 768;
  const conversationLength = messages?.length || 0;
  const [editingMessage, setEditingMessage] = useState(null);
  const [isDataSourceDialogOpen, setIsDataSourceDialogOpen] = useState(false);
  const [showBackgroundTooltip, setShowBackgroundTooltip] = useState(false);
  const [isRunModeMenuOpen, setIsRunModeMenuOpen] = useState(false);

  // Use the useVoiceInput hook directly
  const {
    isListening,
    startListening,
    stopListening,
    error: voiceInputError,
  } = useVoiceInput();

  const resizeTextarea = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      const minHeight = window.innerWidth >= 768 ? '40px' : '20px';
      inputRef.current.style.height = `${Math.max(inputRef.current.scrollHeight, parseInt(minHeight))}px`;
    }
  }, []);

  const debouncedResizeTextarea = useCallback(
    debounce(resizeTextarea, 100),
    [resizeTextarea]
  );

  useEffect(() => {
    debouncedResizeTextarea();
    return () => debouncedResizeTextarea.cancel();
  }, [inputValue, debouncedResizeTextarea]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (inputValue.trim() && !isMessageLoading) {
      if (editingMessage) {
        handleSendMessage(editingMessage.created_at, inputValue.trim())
          .then(() => {
            setEditingMessage(null);
            setInputValue('');
            setCollapseInternalMessages(false);
          })
          .catch(error => setError(error.message));
      } else {
        handleSendMessage(null, inputValue.trim())
          .then(() => {
            setInputValue('');
            setCollapseInternalMessages(false);
          })
          .catch(error => setError(error.message));
      }
    }
  };

  const handleSendOrAbort = () => {
    if (isMessageLoading) {
      abortChat();
    } else {
      handleFormSubmit({ preventDefault: () => {} });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleFormSubmit(e);
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    debouncedResizeTextarea();

    if (value.includes("@")) {
      const query = value.split("@").pop().toLowerCase();
      const filteredAgents = activeAgents.filter(agent => 
        agent.name.toLowerCase().includes(query)
      );
      setSuggestions(filteredAgents);

      const rect = event.target.getBoundingClientRect();
      const position = event.target.selectionStart;
      const caret = getCaretCoordinates(event.target, position);
      const suggestionBoxHeight = Math.min(filteredAgents.length * 40, 208);
      setCursorPos({
        top: rect.top + caret.top + window.scrollY - suggestionBoxHeight - 10,
        left: rect.left + caret.left + window.scrollX
      });
    } else {
      setSuggestions([]);
    }
  };

  const handleAgentSelection = (agent) => {
    const textarea = inputRef.current;
    if (textarea) {
      const selectionStart = textarea.selectionStart;
      const selectionEnd = textarea.selectionEnd;
      const textBefore = inputValue.substring(0, selectionStart).replace(/@\S*$/, '');
      const textAfter = inputValue.substring(selectionEnd);
      const newValue = `${textBefore}@${agent.name} ${textAfter}`;
      setInputValue(newValue);

      const newCursorPosition = textBefore.length + agent.name.length + 2; // +2 for '@' and space
      setTimeout(() => {
        textarea.setSelectionRange(newCursorPosition, newCursorPosition);
        textarea.focus();
      }, 0);

      if (!selectedAgents.find(a => a.id === agent.id)) {
        setSelectedAgents([...selectedAgents, agent]);
      }
    }
    setSuggestions([]);
  };

  useEffect(() => {
    if (editingMessage) {
      setInputValue(editingMessage.content);
      inputRef.current.focus();
    }
  }, [editingMessage]);

  useEffect(() => {
    setIsIPhoneDevice(isIPhone());
  }, []);

  const handleVoiceInput = async () => {
    if (isIPhoneDevice) {
      setError("Voice input is currently not supported on iPhone devices.");
      return;
    }

    if (isListening) {
      // Stop listening and get the audio blob
      const audioBlob = await stopListening();
      if (audioBlob) {
        handleSendMessage(null, null, audioBlob);
      } else {
        console.error('No audio recorded');
      }
    } else {
      startListening();
    }
  };

  useEffect(() => {
    if (voiceInputError) {
      setError(voiceInputError);
    }
  }, [voiceInputError]);

  const isLoading = isMessageLoading || isAddingAgent;

  const handleCancelEdit = () => {
    setEditingMessage(null);
    setInputValue('');
  };

  const handleNewChat = async () => {
    if (selectedProjectId && !isCreatingNewThread && !isLoading) {
      try {
        await createThread();
        setInputValue(''); // Clear input when creating new chat
        setIsSidebarCollapsed(true); // Add this line to collapse the sidebar
      } catch (error) {
        setError(error.message);
      }
    }
  };

  const renderInputState = () => {
    if (isWaitingForInput && inputTimeout) {
      return (
        <div className="text-sm text-gray-400 mt-1">
          <span>Optional input requested. Continuing in {inputTimeout}s...</span>
        </div>
      );
    } else if (isWaitingForInput) {
      return (
        <div className="text-sm text-blue-400 mt-1">
          <span>Input needed to continue...</span>
        </div>
      );
    }
    return null;
  };

  const handlePaperclipClick = () => {
    if (!selectedThreadId) return;
    setIsDataSourceDialogOpen(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isRunModeMenuOpen && !event.target.closest('.run-mode-menu')) {
        setIsRunModeMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isRunModeMenuOpen]);

  return quickStart ? (
    <div className="relative">
      <form onSubmit={handleFormSubmit} className="flex">
        <textarea
          ref={ref}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleFormSubmit(e);
            }
            if (onKeyPress) onKeyPress(e);
          }}
          placeholder="Type what you want to do..."
          rows={3}
          className="flex-1 p-4 bg-gray-700 text-gray-200 border border-gray-600 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
          disabled={isMessageLoading}
        />
        <button
          type="submit"
          disabled={isMessageLoading || !inputValue.trim()}
          className="absolute right-3 bottom-4 p-2 text-blue-400 hover:text-blue-500 disabled:opacity-50"
        >
          {isMessageLoading ? (
            <Loader className="animate-spin" size={20} />
          ) : (
            <Send size={20} />
          )}
        </button>
        {renderInputState()}
      </form>
    </div>
  ) : (
    <>
      <div className="flex flex-col w-full bg-gray-700 rounded-b-lg shadow-lg">
        <form className={`flex flex-col ${isSmallScreen ? 'p-1' : 'p-1.5'}`} onKeyDown={handleKeyPress} onSubmit={handleFormSubmit}>
          <div className={`flex items-center ${isSmallScreen ? 'gap-0.5' : 'gap-1.5'}`}>
            {!editingMessage && (
              <>
                <button
                  type="button"
                  onClick={handlePaperclipClick}
                  className={`${isSmallScreen ? 'h-8 w-8' : 'h-9 w-9'} flex items-center justify-center rounded-lg
                    bg-gray-700/50 text-gray-200 
                    hover:bg-gray-600 hover:text-blue-400
                    transition-all duration-200 
                    ${!selectedThreadId ? 'opacity-50 cursor-not-allowed' : ''}
                  `}
                  disabled={!selectedThreadId}
                  title="Add Data Source"
                >
                  <Paperclip size={isSmallScreen ? 16 : 18} />
                </button>

                <RunModeSelect
                  runMode={runMode}
                  onRunModeChange={onRunModeChange}
                  isSmallScreen={isSmallScreen}
                  runStatus={runStatus}
                />
              </>
            )}

            <div className="relative flex-grow flex items-center">
              {isListening ? (
                <div className="w-full h-10 bg-gray-800/50 backdrop-blur-sm border border-gray-600 rounded-lg px-4 flex items-center">
                  <div className="flex-grow flex justify-center items-center">
                    <div className="audio-wave">
                      {[...Array(5)].map((_, index) => (
                        <div key={index} className="audio-bar"></div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <StyledTextarea
                  ref={inputRef}
                  name="message"
                  value={inputValue}
                  onChange={handleInputChange}
                  placeholder={editingMessage ? "Edit your message..." : "Type your message..."}
                  className={`w-full bg-gray-800/50 backdrop-blur-sm text-gray-200 
                    border border-gray-600 rounded-lg 
                    ${isSmallScreen ? 'px-2 py-1.5 text-sm' : 'px-3 py-2'}
                    focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:border-transparent
                    resize-none overflow-hidden shadow-sm
                    ${isSmallScreen ? 'min-h-[32px]' : 'min-h-[36px]'}
                    transition-all duration-200
                    ${inputValue.length > 0 ? 'border-blue-500/50' : ''}
                    ${disabled ? 'opacity-50 cursor-not-allowed' : ''}
                    hover:border-gray-500
                  `}
                  style={{
                    maxHeight: window.innerWidth >= 768 ? '160px' : '60px',
                    lineHeight: '1.4',
                  }}
                  disabled={disabled}
                />
              )}
              {suggestions.length > 0 && (
                <ul
                  ref={suggestionsRef}
                  className="absolute bg-gray-800 text-gray-200 border border-gray-600 rounded-lg z-10 max-h-40 overflow-auto p-1 shadow-xl"
                  style={{ bottom: '100%', left: 0, minWidth: '150px', maxWidth: '300px' }}
                >
                  {suggestions.map((agent) => (
                    <li
                      key={agent.id}
                      onClick={() => handleAgentSelection(agent)}
                      className="px-3 py-2 cursor-pointer hover:bg-gray-700 rounded-md list-none flex items-center transition-colors duration-150"
                    >
                      {agent.name}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {!editingMessage && !isIPhoneDevice && (
              <button
                type="button"
                onClick={handleVoiceInput}
                className={`${isSmallScreen ? 'h-8 w-8' : 'h-9 w-9'} flex items-center justify-center rounded-lg
                  ${isListening 
                    ? 'bg-red-500/90 text-white hover:bg-red-600' 
                    : 'bg-gray-700/50 text-gray-200 hover:bg-gray-600 hover:text-blue-400'
                  } transition-all duration-200 ${
                    isLoading ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                disabled={isLoading}
              >
                {isListening ? <MicOff size={isSmallScreen ? 16 : 18} /> : <Mic size={isSmallScreen ? 16 : 18} />}
              </button>
            )}
            <button
              type="button"
              onClick={handleSendOrAbort}
              className={`${isSmallScreen ? 'h-8 w-8' : 'h-9 w-9'} flex items-center justify-center rounded-lg
                transition-all duration-200 ${
                  isMessageLoading 
                    ? 'bg-red-500/90 text-white hover:bg-red-600' 
                    : (activeAgents.length === 0 || !inputValue.trim() || isListening)
                      ? 'bg-gray-700/50 text-gray-400 cursor-not-allowed'
                      : 'bg-blue-500/90 text-white hover:bg-blue-600'
                }`}
              disabled={isListening || (!isMessageLoading && (activeAgents.length === 0 || !inputValue.trim()))}
            >
              {isMessageLoading ? <Square size={isSmallScreen ? 16 : 18} /> : 
               editingMessage ? <Check size={isSmallScreen ? 16 : 18} /> : <Send size={isSmallScreen ? 16 : 18} />}
            </button>
            {editingMessage && (
              <button
                type="button"
                onClick={handleCancelEdit}
                className={`${isSmallScreen ? 'h-8 w-8' : 'h-9 w-9'} flex items-center justify-center rounded-lg 
                  bg-gray-700 text-gray-200 transition-colors duration-150 hover:bg-gray-600`}
              >
                <X size={isSmallScreen ? 16 : 18} />
              </button>
            )}
          </div>
        </form>
        <style>{`
          .audio-wave {
            display: flex;
            align-items: center;
            height: 24px;
            gap: 3px;
          }
          .audio-bar {
            width: 3px;
            height: 100%;
            background-color: #60A5FA;
            border-radius: 6px;
            animation: audio-wave 0.8s ease-in-out infinite;
          }
          .audio-bar:nth-child(1) { animation-delay: 0s; }
          .audio-bar:nth-child(2) { animation-delay: 0.2s; }
          .audio-bar:nth-child(3) { animation-delay: 0.4s; }
          .audio-bar:nth-child(4) { animation-delay: 0.6s; }
          .audio-bar:nth-child(5) { animation-delay: 0.8s; }
          @keyframes audio-wave {
            0%, 100% { transform: scaleY(0.3); }
            50% { transform: scaleY(1); }
          }
        `}</style>
      </div>
      <DataSourceDialog
        isOpen={isDataSourceDialogOpen}
        onClose={() => setIsDataSourceDialogOpen(false)}
        onSubmit={handleAddConversationDataSource}
      />
    </>
  );
});

InputForm.displayName = 'InputForm';

export default InputForm;
