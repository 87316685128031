import { Terminal, Globe, FileSearch, Newspaper, Plus, Minus, Trash } from 'lucide-react';
import { AgentIcon } from '../components/ui/Icons';

// Color mapping for different tool types
export const TOOL_CONFIGS = {
  'file_search': {
    icon: FileSearch,
    color: 'text-orange-500',
    label: 'File Search'
  },
  'web_search': {
    icon: Globe,
    color: 'text-blue-500',
    label: 'Web Search'
  },
  'news_search': {
    icon: Newspaper,
    color: 'text-yellow-500',
    label: 'News Search'
  },
  'create_agent': {
    icon: AgentIcon,
    color: 'text-green-500',
    label: 'Create Agent'
  },
  'add_agent': {
    icon: Plus,
    color: 'text-green-500',
    label: 'Add Agent'
  },
  'remove_agent': {
    icon: Minus,
    color: 'text-red-500',
    label: 'Remove Agent'
  },
  'delete_agent': {
    icon: Trash,
    color: 'text-red-500',
    label: 'Delete Agent'
  },
  'default': {
    icon: Terminal,
    color: 'text-sky-500',
    label: 'Tool Output'
  }
};

export const getToolConfig = (name) => {
  // Convert name to lowercase and replace spaces/special chars with underscores
  const toolType = name.toLowerCase().replace(/[\s-]/g, '_');
  return TOOL_CONFIGS[toolType] || TOOL_CONFIGS.default;
}; 