import React, { useState } from 'react';
import { X, Copy, Check, Loader2, ExternalLink, Maximize2, Minimize2, History, ChevronLeft, ChevronRight } from 'lucide-react';
import SharedMarkdown from '../utils/SharedMarkdown';
import { getArtifactIcon } from '../utils/iconUtils';
import { formatNameWithLink } from '../utils/urlUtils';
import copy from 'clipboard-copy';
import { truncateString, getTooltipText } from '../utils/stringUtils';
import ErrorPopup from '../components/ErrorPopup';
import useArtifacts from '../hooks/useArtifacts';
import { FileViewer, ViewerControls, JsonView } from './FileViewers';
import ArtifactNavigation from './ArtifactNavigation';
import { getToolConfig } from '../utils/toolConfigs';
import ToolOutputViewer from './ToolOutputViewer';

const Workbench = ({ 
  selectedArtifactId, 
  selectedToolOutput, 
  projectId, 
  threadId, 
  isSmallScreen, 
  onClose,
  isFullscreen,
  setIsFullscreen,
  updateProject,
  projectData,
  agentData,
  updateAgent,
  getAgentWithVersions,
  models,
  removeDataSource,
  addDataSource,
  getAgentData
}) => {
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState(null);
  const [viewerType, setViewerType] = useState('google');
  const [darkMode, setDarkMode] = useState(false);
  const [showVersions, setShowVersions] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const { 
    navigation, 
    handlePrevious, 
    handleNext, 
    currentArtifact, 
    isLoading 
  } = useArtifacts(selectedArtifactId, projectId, threadId);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full text-gray-400">
        <Loader2 className="w-6 h-6 animate-spin mr-2" />
        <span>Loading artifact...</span>
      </div>
    );
  }

  if (selectedArtifactId && !currentArtifact) {
    console.error('No artifact found for ID:', selectedArtifactId);
    return (
      <div className="flex items-center justify-center h-full text-gray-400">
        <span>No artifact found</span>
      </div>
    );
  }

  const handleCopy = async () => {
    try {
      let textToCopy;
      if (selectedToolOutput) {
        const { message, toolOutputIndex } = selectedToolOutput;
        const output = message.tool_outputs[toolOutputIndex];
        textToCopy = output.output;
      } else if (currentArtifact) {
        const version = selectedVersion || currentArtifact.active_version;
        
        switch (currentArtifact.type) {
          case 'document':
          case 'presentation':
            if (version?.url) {
              textToCopy = version.url;
            } else {
              throw new Error('No URL available');
            }
            break;
          default:
            textToCopy = currentArtifact.markdown || 
                         JSON.stringify(version?.structured_output || currentArtifact.structured_output, null, 2);
        }
      }

      if (!textToCopy) {
        throw new Error('No content available to copy');
      }

      await copy(textToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      setError('Failed to copy content to clipboard');
    }
  };

  const renderToolOutput = () => {
    const { message, toolOutputIndex } = selectedToolOutput;
    const output = message.tool_outputs[toolOutputIndex];
    
    return (
      <ToolOutputViewer
        output={output}
        projectData={projectData}
        updateProject={updateProject}
        agentData={agentData}
        updateAgent={updateAgent}
        getAgentWithVersions={getAgentWithVersions}
        models={models}
        removeDataSource={removeDataSource}
        addDataSource={addDataSource}
        getAgentData={getAgentData}
      />
    );
  };

  const renderVersionHistory = () => {
    if (!currentArtifact?.versions?.length) return null;

    const versions = currentArtifact.versions;
    const activeVersion = versions.find(v => v.is_active) || versions[0];
    const currentVersion = selectedVersion || activeVersion;
    const currentIndex = versions.findIndex(v => v.created_at === currentVersion.created_at);

    return (
      <div className="absolute bottom-0 left-0 right-0 bg-gray-700/95 border-t border-gray-600/30">
        <div className="flex items-center justify-between p-2">
          <button
            onClick={() => currentIndex > 0 && setSelectedVersion(versions[currentIndex - 1])}
            disabled={currentIndex === 0}
            className={`p-1 rounded ${currentIndex === 0 ? 'text-gray-500' : 'text-gray-300 hover:text-white hover:bg-gray-700'}`}
          >
            <ChevronLeft size={16} />
          </button>

          <div className="flex-1 mx-4 text-center min-w-0">
            <div className="text-xs text-gray-200">
              Version {currentIndex + 1} of {versions.length}
              {currentVersion.is_active && <span className="ml-2 text-indigo-400">(Active)</span>}
            </div>
            <div className="text-xs text-gray-400 truncate">
              {new Date(currentVersion.created_at * 1000).toLocaleString()}
            </div>
            {currentVersion.change_summary && (
              <div className="text-xs text-gray-400 truncate">
                {currentVersion.change_summary}
              </div>
            )}
          </div>

          <div className="flex items-center gap-2 flex-shrink-0">
            <button
              onClick={() => currentIndex < versions.length - 1 && setSelectedVersion(versions[currentIndex + 1])}
              disabled={currentIndex === versions.length - 1}
              className={`p-1 rounded ${currentIndex === versions.length - 1 ? 'text-gray-500' : 'text-gray-300 hover:text-white hover:bg-gray-700'}`}
            >
              <ChevronRight size={16} />
            </button>
            <button
              onClick={() => {
                setShowVersions(false);
                setSelectedVersion(activeVersion);
              }}
              className="p-1 rounded text-gray-400 hover:text-white hover:bg-gray-700"
              title="Close version history"
            >
              <X size={16} />
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderArtifact = () => {
    if (!currentArtifact.url && !currentArtifact.markdown && !currentArtifact.structured_output) {
        return (
            <div className="flex items-center justify-center h-full text-gray-400">
                <span>No content available</span>
            </div>
        );
    }

    const version = selectedVersion || currentArtifact.active_version;
    if (!version) return null;

    switch (currentArtifact.type) {
      case 'document':
      case 'presentation':
        if (version.url) {
          return (
            <div className="flex flex-col h-full">
              <FileViewer
                url={version.url}
                fileType={currentArtifact.type}
                viewerType={viewerType}
                darkMode={darkMode}
              />
              {currentArtifact.description && (
                <div className="mt-2 p-2 bg-gray-700 rounded-md">
                  <p className="text-xs text-gray-300">{currentArtifact.description}</p>
                </div>
              )}
            </div>
          );
        }
        break;

      case 'plan':
      case 'evaluation':
        if (version.structured_output) {
          return <JsonView data={version.structured_output} />;
        }
        break;
    }

    if (currentArtifact.markdown) {
      return (
        <div className="prose prose-sm prose-invert max-w-none text-xs">
          <SharedMarkdown className="text-xs leading-relaxed" isWorkbench={true}>
            {currentArtifact.markdown}
          </SharedMarkdown>
        </div>
      );
    }
  };

  const getHeaderTitle = () => {
    if (selectedToolOutput) {
      const { message, toolOutputIndex } = selectedToolOutput;
      return message.tool_outputs[toolOutputIndex].name;
    }
    return currentArtifact?.name || 'No title';
  };

  const getHeaderIcon = () => {
    if (selectedToolOutput) {
      const { message, toolOutputIndex } = selectedToolOutput;
      const toolName = message.tool_outputs[toolOutputIndex].name;
      const config = getToolConfig(toolName);
      const IconComponent = config.icon;
      return <IconComponent size={14} className={config.color} />;
    }
    return currentArtifact ? getArtifactIcon(currentArtifact.type) : null;
  };

  return (
    <div className={`detail-panel flex flex-col h-full font-sans text-sm relative z-40 
      bg-gray-900/95 backdrop-blur-md rounded-lg ${isFullscreen ? 'fixed inset-0 rounded-none' : ''}`}>
      {/* Header - lighter color to match InputForm */}
      <div className="bg-gray-700/90 h-10 flex justify-between items-center px-3 
        border-b border-gray-600/30 rounded-t-lg">
        <h2 className="text-sm font-medium text-gray-200 flex items-center font-sans gap-2 min-w-0 mr-4">
          <span className="flex-shrink-0">{getHeaderIcon()}</span>
          <span className="truncate min-w-0" title={getHeaderTitle()}>{getHeaderTitle()}</span>
        </h2>
        <div className="flex items-center flex-shrink-0">
          <div className="flex">
            <button 
              onClick={() => setIsFullscreen(!isFullscreen)} 
              className="text-gray-400 hover:text-gray-200 transition-colors p-1.5
                hover:bg-gray-600/50"
              title={isFullscreen ? "Exit fullscreen" : "Enter fullscreen"}
            >
              {isFullscreen ? <Minimize2 size={16} /> : <Maximize2 size={16} />}
            </button>
            <button 
              onClick={onClose} 
              className="text-gray-400 hover:text-gray-200 transition-colors p-1.5
                hover:bg-gray-600/50"
              title="Close"
            >
              <X size={16} />
            </button>
          </div>
        </div>
      </div>
      
      {/* Content */}
      <div className="flex-1 min-h-0 overflow-hidden flex flex-col relative">
        <div className="flex-1 overflow-y-auto">
          <div className={`h-full ${selectedToolOutput || !currentArtifact?.url ? 'px-3' : ''}`}>
            {selectedToolOutput ? renderToolOutput() : renderArtifact()}
          </div>
        </div>
        {showVersions && renderVersionHistory()}
      </div>

      {/* Footer - lighter color to match InputForm */}
      <div className="bg-gray-700/90 h-10 flex justify-between items-center px-3 
        border-t border-gray-600/30 rounded-b-lg">
        <div className="flex gap-2">
          {!selectedToolOutput && (
            <>
              <div className="mr-2">
                <ArtifactNavigation
                  onPrevious={handlePrevious}
                  onNext={handleNext}
                  navigation={navigation}
                />
              </div>

              {currentArtifact?.url && (
                <ViewerControls
                  viewerType={viewerType}
                  setViewerType={setViewerType}
                  darkMode={darkMode}
                  setDarkMode={setDarkMode}
                />
              )}
            </>
          )}
        </div>
        
        <div className="flex items-center gap-2">
          {currentArtifact?.versions?.length > 1 && (
            <button 
              onClick={() => setShowVersions(!showVersions)} 
              className={`flex items-center gap-1.5 text-gray-400 hover:text-gray-200 
                transition-colors p-1.5 rounded-md hover:bg-gray-600/50 ${
                showVersions ? 'text-blue-400 bg-gray-700/30' : ''
              }`}
            >
              <History size={14} />
              <span className="text-xs">{currentArtifact.versions.length} revisions</span>
            </button>
          )}
          <button
            onClick={handleCopy}
            className="text-gray-400 hover:text-gray-200 transition-colors p-1.5 rounded-md 
              hover:bg-gray-600/50"
          >
            {copied ? <Check size={14} className="text-green-500" /> : <Copy size={14} />}
          </button>
        </div>
      </div>

      {/* Version history panel */}
      {showVersions && (
        <div className="absolute bottom-10 left-0 right-0 bg-gray-900/95 
          border-t border-gray-700/30 shadow-lg backdrop-blur-md">
          <div className="flex items-center justify-between">
            {/* ... version history content ... */}
          </div>
        </div>
      )}

      {error && (
        <div className="fixed inset-0 bg-gray-900/80 backdrop-blur-sm flex justify-center items-center z-50">
          <ErrorPopup message={error} onClose={() => setError(null)} />
        </div>
      )}
    </div>
  );
};

export default Workbench;
