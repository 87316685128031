import React from 'react';
import { ChevronUp, Loader2 } from 'lucide-react';
import Button from './Button';

const LoadMoreButton = ({ onClick, isLoading, className = '' }) => {
  return (
    <div className="flex justify-center py-2">
      <button
        onClick={onClick}
        disabled={isLoading}
        className={`flex items-center gap-1.5 px-3 py-1.5 text-xs text-gray-400 
          hover:text-gray-300 hover:bg-gray-800/50
          transition-colors duration-150 rounded-md ${className}`}
      >
        {isLoading ? (
          <>
            <Loader2 className="h-3 w-3 animate-spin" />
            <span>Loading...</span>
          </>
        ) : (
          <>
            <ChevronUp className="h-3 w-3" />
            <span>Show previous messages</span>
          </>
        )}
      </button>
    </div>
  );
};

export default LoadMoreButton; 