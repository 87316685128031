import React, { useState, useRef } from 'react';
import { Upload, Link, File, Table, Loader2 } from 'lucide-react';
import Button from './ui/Button';
import Modal from './ui/Modal';

const DataSourceDialog = ({ isOpen, onClose, onSubmit}) => {
  const [inputType, setInputType] = useState('file');
  const [dataType, setdataType] = useState('unstructured');
  const [urlDataSource, setUrlDataSource] = useState('');
  const [file, setFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const handleSubmit = async () => {
    const dataSourceRequest = {
      inputType: inputType,
      dataType: dataType,
    };
    if (inputType === 'file') {
      dataSourceRequest.file = file;
    } else if (inputType === 'url') {
      dataSourceRequest.url = urlDataSource;
    }
    setIsSubmitting(true);
    try {
      await onSubmit(dataSourceRequest);
      resetForm();
      onClose();
    } catch (error) {
      console.error('Error submitting data source:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetForm = () => {
    setInputType('file');
    setdataType('unstructured');
    setUrlDataSource('');
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title="Add Data Source"
      color="indigo"
      disabled={isSubmitting}
    >
      <div className="p-6 space-y-6">
        <div className="flex flex-col space-y-2">
          <label className="text-sm font-medium text-gray-300">Source Type</label>
          <div className="flex space-x-3">
            <Button
              variant={dataType === 'unstructured' ? 'primary' : 'secondary'}
              onClick={() => {
                setInputType('file');
                setdataType('unstructured');
              }}
              size="sm"
              className="flex-1"
              icon={<File className="h-4 w-4 mr-2" />}
            >
              File
            </Button>
            <Button
              variant={inputType === 'url' ? 'primary' : 'secondary'}
              onClick={() => {
                setInputType('url');
                setdataType('unstructured');
              }}
              size="sm"
              className="flex-1"
              icon={<Link className="h-4 w-4 mr-2" />}
            >
              URL
            </Button>
          </div>
        </div>

        {inputType === 'file' && (
          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-300">Upload File</label>
            <div className="flex items-center justify-center w-full">
              <label 
                htmlFor="file-upload" 
                className="flex flex-col items-center justify-center w-full h-32
                  border-2 border-indigo-500/20 border-dashed rounded-lg cursor-pointer 
                  bg-gray-700/50 hover:bg-gray-700/70 transition-colors duration-150
                  focus-within:border-indigo-500/50 focus-within:bg-gray-700/70"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  {!file ? (
                    <>
                      <Upload className="w-8 h-8 mb-3 text-indigo-400" />
                      <p className="mb-2 text-sm text-indigo-300">
                        <span className="font-semibold">Click to upload</span> or drag and drop
                      </p>
                      <p className="text-xs text-indigo-300/70">
                        PDF, DOC, TXT, HTML, JSON, and more
                      </p>
                    </>
                  ) : (
                    <>
                      <File className="w-8 h-8 mb-3 text-indigo-400" />
                      <p className="text-sm text-indigo-300 font-medium">{file.name}</p>
                      <p className="text-xs text-indigo-300/70 mt-1">
                        Click to change file
                      </p>
                    </>
                  )}
                </div>
                <input
                  id="file-upload"
                  type="file"
                  className="hidden"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept=".txt,.pdf,.doc,.docx,.html,.json,.py,.js,.zip,.tar,.gz,.jpg,.webp,.png,.mp3,.mp4"
                />
              </label>
            </div>
          </div>
        )}

        {inputType === 'url' && (
          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-300">URL</label>
            <input
              type="url"
              value={urlDataSource}
              onChange={(e) => setUrlDataSource(e.target.value)}
              placeholder="https://example.com/data-source"
              className="w-full px-4 py-2.5 bg-gray-700/50 border border-indigo-500/20 
                rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500/50 
                text-indigo-100 placeholder-indigo-300/30"
            />
          </div>
        )}
      </div>

      <div className="border-t border-indigo-500/20 p-6 flex justify-end space-x-3">
        <Button
          variant="secondary"
          onClick={handleClose}
          disabled={isSubmitting}
          size="sm"
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={isSubmitting || (inputType === 'file' && !file) || (inputType === 'url' && !urlDataSource)}
          size="sm"
        >
          {isSubmitting ? (
            <>
              <Loader2 className="h-4 w-4 mr-2 animate-spin" />
              Submitting
            </>
          ) : (
            <>
              <Upload className="h-4 w-4 mr-2" />
              Submit
            </>
          )}
        </Button>
      </div>
    </Modal>
  );
};

export default DataSourceDialog;