import React, { useRef, useEffect, useState } from 'react';
import { Database, Plus, X, ChevronLeft, ChevronRight } from 'lucide-react';
import Button from './ui/Button';
import LoadingButton from './ui/LoadingButton';
import DataSourceDialog from './DataSourceDialog';

const AgentForm = ({
  agentData,
  setAgentData,
  readOnly = false,
  models = [],
  onAddDataSource,
  onRemoveDataSource,
  dataSources = [],
  isNewAgent = false
}) => {
  const [isDataSourceDialogOpen, setIsDataSourceDialogOpen] = useState(false);
  const [removingDataSourceId, setRemovingDataSourceId] = useState(null);
  const [currentVersionIndex, setCurrentVersionIndex] = useState(0);
  const descriptionRef = useRef(null);
  const instructionsRef = useRef(null);

  // Get versions from agentData
  const versions = agentData?.versions || [];
  const activeVersion = versions.find(v => v.is_active) || versions[0];

  // Handle version selection
  const handleVersionSelect = (version) => {
    if (!version || readOnly) return;
    
    setAgentData({
      ...agentData,
      description: version.description,
      instructions: version.instructions,
      model: version.model,
      versions: agentData.versions
    });
    setCurrentVersionIndex(versions.findIndex(v => v.created_at === version.created_at));
  };

  const adjustTextareaHeight = (element) => {
    if (element) {
      element.style.height = 'auto';
      element.style.height = `${element.scrollHeight}px`;
    }
  };

  // Adjust height when content changes
  useEffect(() => {
    adjustTextareaHeight(descriptionRef.current);
    adjustTextareaHeight(instructionsRef.current);
  }, [agentData?.description, agentData?.instructions]);

  // Adjust height on initial render
  useEffect(() => {
    adjustTextareaHeight(descriptionRef.current);
    adjustTextareaHeight(instructionsRef.current);
  }, []);

  // Update the initial version index based on active version
  useEffect(() => {
    if (versions.length > 0) {
      const activeVersionIndex = versions.findIndex(v => v.is_active);
      if (activeVersionIndex !== -1) {
        setCurrentVersionIndex(activeVersionIndex);
      }
    }
  }, [versions]);

  const data = {
    name: agentData?.name || '',
    description: agentData?.description || '',
    instructions: agentData?.instructions || '',
    model: agentData?.model || '',
  };

  const handleRemoveDataSource = async (dataSourceId) => {
    try {
      setRemovingDataSourceId(dataSourceId);
      await onRemoveDataSource?.(dataSourceId);
    } catch (error) {
      console.error('Error removing data source:', error);
    } finally {
      setRemovingDataSourceId(null);
    }
  };

  const renderDataSources = () => {
    if (isNewAgent) return null;

    return (
      <div className="mt-6">
        <div className="flex justify-between items-center mb-2">
          <label className="block text-sm font-medium text-gray-200">
            Data Sources
          </label>
          {!readOnly && (
            <Button
              variant="secondary"
              onClick={() => setIsDataSourceDialogOpen(true)}
              icon={<Plus size={16} />}
              className="text-xs"
            >
              Add 
            </Button>
          )}
        </div>
        
        <div className="space-y-2">
          {dataSources.map((source) => (
            <div 
              key={source.id} 
              className="flex items-center justify-between p-2 bg-gray-800/50 rounded-lg"
            >
              <div className="flex items-center space-x-2">
                <Database size={16} className="text-gray-400" />
                <div className="text-sm text-gray-200">
                  {source.name || source.url}
                </div>
              </div>
              {!readOnly && (
                <LoadingButton
                  variant="icon"
                  onClick={() => handleRemoveDataSource(source.id)}
                  isLoading={removingDataSourceId === source.id}
                  loadingText=""
                  className="text-gray-400 hover:text-red-400"
                  icon={<X size={16} />}
                />
              )}
            </div>
          ))}
          {dataSources.length === 0 && (
            <div className="text-sm text-gray-400 text-center py-4">
              No data sources added yet
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderVersionHistory = () => {
    if (!versions || versions.length === 0) return null;

    const currentVersion = versions[currentVersionIndex];
    if (!currentVersion) return null;
    
    const hasMultipleVersions = versions.length > 1;

    return (
      <div className="mt-6">
        <label className="block text-sm font-medium text-gray-200 mb-2">
          Version History ({currentVersionIndex + 1} of {versions.length})
        </label>
        <div className="relative">
          {hasMultipleVersions && (
            <>
              <button
                type="button"
                onClick={() => currentVersionIndex > 0 && setCurrentVersionIndex(prev => prev - 1)}
                disabled={currentVersionIndex === 0}
                className={`absolute left-0 top-1/2 transform -translate-y-1/2 p-2 rounded-full z-10
                  ${currentVersionIndex === 0 ? 'text-gray-500' : 'text-gray-400 hover:text-gray-300'}`}
              >
                <ChevronLeft size={24} />
              </button>
              
              <button
                type="button"
                onClick={() => currentVersionIndex < versions.length - 1 && setCurrentVersionIndex(prev => prev + 1)}
                disabled={currentVersionIndex === versions.length - 1}
                className={`absolute right-0 top-1/2 transform -translate-y-1/2 p-2 rounded-full z-10
                  ${currentVersionIndex === versions.length - 1 ? 'text-gray-500' : 'text-gray-400 hover:text-gray-300'}`}
              >
                <ChevronRight size={24} />
              </button>
            </>
          )}

          <div 
            className="mx-8 p-3 rounded-lg border bg-gray-800/50 border-gray-700/50 cursor-pointer"
            onClick={() => !readOnly && handleVersionSelect(currentVersion)}
          >
            <div className="flex justify-between items-center">
              <span className="text-sm text-gray-200">
                {currentVersion.created_at ? 
                  new Date(currentVersion.created_at * 1000).toLocaleString() :
                  'Unknown date'
                }
              </span>
              {currentVersion.is_active && (
                <span className="text-xs text-blue-400 px-2 py-1 rounded-full bg-blue-900/50">
                  Active
                </span>
              )}
            </div>
            <div className="mt-2 text-sm text-gray-400">
              {currentVersion.change_summary && (
                <div className="truncate">{currentVersion.change_summary}</div>
              )}
              {!readOnly && (
                <div className="mt-2 text-xs text-blue-300">Click to load this version</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Read-only view
  if (readOnly) {
    return (
      <div className="space-y-6">
        {/* Agent Name */}
        <div>
          <h3 className="text-sm font-medium text-indigo-100">Agent Name</h3>
          <p className="text-gray-200 bg-gray-700/50 px-4 py-2 rounded-md mt-2">
            {agentData?.name || 'No name'}
          </p>
        </div>

        {/* Description */}
        <div>
          <h3 className="text-sm font-medium text-gray-200">Description</h3>
          <p className="text-gray-200 bg-gray-700/50 px-4 py-2 rounded-md mt-2 whitespace-pre-wrap">
            {agentData?.description || 'No description'}
          </p>
        </div>

        {/* Instructions */}
        <div>
          <h3 className="text-sm font-medium text-gray-200">Instructions</h3>
          <p className="text-gray-200 bg-gray-700/50 px-4 py-2 rounded-md mt-2 whitespace-pre-wrap">
            {agentData?.instructions || 'No instructions'}
          </p>
        </div>

        {/* Model */}
        <div>
          <h3 className="text-sm font-medium text-gray-200">Model</h3>
          <p className="text-gray-200 bg-gray-700/50 px-4 py-2 rounded-md mt-2">
            {models.find(m => m.id === agentData?.model)?.name || 'No model selected'}
          </p>
        </div>

        {/* Add data sources section */}
        {renderDataSources()}

        {/* Add version history section */}
        {!isNewAgent && renderVersionHistory()}
      </div>
    );
  }

  // Editable form
  return (
    <div className="space-y-6">
      {/* Agent Name */}
      <div>
        <label className="block text-sm font-medium text-gray-200 mb-1">
          Agent Name
        </label>
        <input
          type="text"
          value={data.name}
          onChange={(e) => setAgentData?.({ ...data, name: e.target.value })}
          placeholder="Enter agent name"
          className="w-full bg-gray-800/50 text-gray-200 rounded-md px-3 py-2"
          readOnly={readOnly}
        />
      </div>

      {/* Description */}
      <div>
        <label className="block text-sm font-medium text-gray-200 mb-1">
          Description
        </label>
        <textarea
          ref={descriptionRef}
          value={data.description}
          onChange={(e) => {
            setAgentData?.({ ...data, description: e.target.value });
            adjustTextareaHeight(descriptionRef.current);
          }}
          className="w-full px-3 py-2 bg-gray-800/50 text-gray-200 rounded-md resize-none min-h-[80px] whitespace-pre-wrap"
          placeholder="What is this agent's purpose?"
          readOnly={readOnly}
        />
      </div>

      {/* Instructions */}
      <div>
        <label className="block text-sm font-medium text-gray-200 mb-1">
          Instructions
        </label>
        <textarea
          ref={instructionsRef}
          value={data.instructions}
          onChange={(e) => {
            setAgentData?.({ ...data, instructions: e.target.value });
            adjustTextareaHeight(instructionsRef.current);
          }}
          className="w-full px-3 py-2 bg-gray-800/50 text-gray-200 rounded-md resize-none min-h-[120px] whitespace-pre-wrap"
          placeholder="How should this agent behave?"
          readOnly={readOnly}
        />
      </div>

      {/* Model */}
      <div>
        <label className="block text-sm font-medium text-gray-200 mb-1">
          Model
        </label>
        <select
          value={data.model}
          onChange={(e) => setAgentData?.({ ...data, model: e.target.value })}
          className="w-full bg-gray-800/50 text-gray-200 rounded-md px-3 py-2 appearance-none cursor-pointer"
          disabled={readOnly || models.length === 0}
        >
          {models.length === 0 ? (
            <option value="">Loading models...</option>
          ) : (
            models.map(model => (
              <option key={model.id} value={model.id}>
                {model.name}
              </option>
            ))
          )}
        </select>
      </div>

      {/* Add data sources section */}
      {renderDataSources()}

      {/* Add version history section */}
      {!isNewAgent && renderVersionHistory()}

      {/* Data source dialog */}
      {isDataSourceDialogOpen && (
        <DataSourceDialog
          isOpen={isDataSourceDialogOpen}
          onClose={() => setIsDataSourceDialogOpen(false)}
          onSubmit={async (request) => {
            try {
              await onAddDataSource?.(request);
              setIsDataSourceDialogOpen(false);
            } catch (error) {
              console.error('Error adding data source:', error);
            }
          }}
        />
      )}
    </div>
  );
};

export default AgentForm; 