import { useState, useEffect, useCallback } from 'react';
import useApi from './useApi';
import { handleHttpError } from '../utils/errorHandling';

const useProjects = () => {
    const [projects, setProjects] = useState([]);
    const [selectedProjectId, setSelectedProjectId] = useState(() => {
        return localStorage.getItem('lastSelectedProject') || null;
    });
    const [isSharingProject, setIsSharing] = useState(false);

    const [projectData, setProjectData] = useState({
        name: '',
        description: '',
        objective: '',
        keyResults: [],
        metrics: [],
        outputFormat: 'Auto'
    });
    const [isLoading, setIsLoading] = useState(true);
    const { api } = useApi();

    const selectProject = useCallback(async (projectId) => {
        try {
            setSelectedProjectId(projectId);
            
            if (projectId) {
                localStorage.setItem('lastSelectedProject', projectId);
                const response = await api.get(`/projects/${projectId}`);
                const { name, description, objective, krs, metrics, output_format } = response.data;
                
                setProjectData({
                    name: name || '',
                    description: description || '',
                    objective: objective || '',
                    keyResults: krs?.map(kr => ({ description: kr })) || [],
                    metrics: metrics || [],
                    outputFormat: output_format || 'Auto'
                });
            } else {
                localStorage.removeItem('lastSelectedProject');
                setProjectData(null);
            }
        } catch (error) {
            console.error('Error selecting project:', error);
            handleHttpError(error);
        }
    }, [api]);

    const fetchProjects = useCallback(async () => {
        try {
            const response = await api.get('/projects/');
            setProjects(response.data);
            return response.data;
        } catch (err) {
            handleHttpError(err, 'fetching projects');
            return [];
        }
    }, [api]);

    useEffect(() => {
        const initializeProjects = async () => {
            setIsLoading(true);
            try {
                const fetchedProjects = await fetchProjects();
                if (fetchedProjects.length === 0) {
                    const { project } = await createProject({
                        name: "My First Project"
                    });
                    setProjects([project]);
                    await selectProject(project.id);
                } else {
                    const lastSelectedId = localStorage.getItem('lastSelectedProject');
                    const validProject = lastSelectedId && fetchedProjects.some(p => p.id === lastSelectedId);
                    await selectProject(validProject ? lastSelectedId : fetchedProjects[0].id);
                }
            } catch (error) {
                console.error('Error initializing projects:', error);
            } finally {
                setIsLoading(false);
            }
        };

        initializeProjects();
    }, []);

    const updateProject = async (projectId, newSettings) => {
        try {
            const response = await api.put(`/projects/${projectId}`, {
                name: newSettings.name,
                description: newSettings.description,
                objective: newSettings.objective,
                krs: newSettings.keyResults.map(kr => kr.description).filter(Boolean),
                metrics: newSettings.metrics,
                output_format: newSettings.outputFormat
            });

            const updatedProject = {
                ...response.data,
                keyResults: response.data.krs?.map(kr => ({ description: kr })) || [],
                outputFormat: response.data.output_format
            };

            setProjectData(updatedProject);
            setProjects(prevProjects =>
                prevProjects.map(project =>
                    project.id === projectId
                        ? updatedProject
                        : project
                )
            );

            return updatedProject;
        } catch (err) {
            handleHttpError(err, 'updating project settings');
            throw err;
        }
    };

    const createProject = async (projectData) => {
        if (!projectData?.name?.trim()) {
            throw new Error('Project name cannot be empty');
        }
        
        try {
            const response = await api.post('/projects/create', {
                name: projectData.name,
                description: projectData.description || '',
                objective: projectData.objective || '',
                keyResults: projectData.keyResults || [],
                metrics: projectData.metrics || [],
                output_format: projectData.outputFormat || 'Auto'
            });

            const { project, initial_conversation_id } = response.data;
            setProjects(prevProjects => [...prevProjects, project]);
            setSelectedProjectId(project.id);

            return {
                project,
                initialConversationId: initial_conversation_id
            };
        } catch (err) {
            handleHttpError(err, 'creating project');
        }
    };

    const deleteProject = async (projectId) => {
        try {
            await api.delete(`/projects/${projectId}`);
            setProjects(prevProjects => prevProjects.filter(project => project.id !== projectId));
            
            if (selectedProjectId === projectId) {
                const remainingProjects = projects.filter(project => project.id !== projectId);
                if (remainingProjects.length > 0) {
                    setSelectedProjectId(remainingProjects[0].id);
                } else {
                    setSelectedProjectId(null);
                }
            }
        } catch (err) {
            handleHttpError(err, 'deleting project');
        }
    };

    const shareProject = async (projectId, email) => {
        try {
            setIsSharing(true);
            const response = await api.post(`/projects/${projectId}/share`, {
                shared_user_email: email
            });
            return response.data.message;
        } catch (err) {
            handleHttpError(err, 'sharing project');
        } finally {
            setIsSharing(false);
        }
    }

    return {
        projects,
        selectedProjectId,
        projectData,
        isSharingProject,
        isLoading,
        selectProject,
        fetchProjects,
        createProject,
        updateProject,
        deleteProject,
        shareProject
    };
};

export default useProjects;
