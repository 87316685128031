import React, { useState, useEffect, useRef } from 'react';
import { User, Menu, Users } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome as Home } from '@fortawesome/free-solid-svg-icons';
import Button from './ui/Button';
import useTokens from '../hooks/useTokens';
import TokenDisplay from './ui/TokenDisplay';
import TokenUsageDetails from './ui/TokenUsageDetails';
import useApi from '../hooks/useApi';

const Header = ({
    isLoggedIn,
    user,
    handleLogOut,
    showDashboard,
    setShowDashboard,
    selectedProjectId,
    projects,
    isSmallScreen,
    isSidebarCollapsed,
    setIsCollapsed,
    isAdmin,
    showUserManager,
    setShowUserManager,
}) => {
    const [showLogout, setShowLogout] = useState(false);
    const [showTokens, setShowTokens] = useState(false);
    const menuRef = useRef(null);
    const tokenRef = useRef(null);
    const { usage, isLoading: isLoadingTokens } = useTokens();
    const [showTokenDetails, setShowTokenDetails] = useState(false);
    const { getUsage } = useApi();
    
    const currentProject = projects?.find(p => p.id === selectedProjectId);
    const projectName = currentProject?.name || 'Select a Project';
    const userName = user?.name?.split(' ')[0] || user?.name || 'there';

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowLogout(false);
            }
        };

        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                setShowLogout(false);
            }
        };

        // Add event listeners
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleEscapeKey);

        // Cleanup
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (tokenRef.current && !tokenRef.current.contains(event.target)) {
                setShowTokens(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    // Add handler for showing token details with logging
    const handleShowTokenDetails = () => {
        setShowTokenDetails(true);
        getUsage();
    };

    // Add escape key handler for token details modal
    useEffect(() => {
        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                setShowTokenDetails(false);
            }
        };

        if (showTokenDetails) {
            document.addEventListener('keydown', handleEscapeKey);
        }

        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [showTokenDetails]);

    return (
        <>
            <header className={`fixed top-0 left-0 right-0 text-white w-full p-1 z-50
                ${showDashboard ? 'bg-gray-900' : 'bg-gray-700/90 backdrop-blur-sm border-b border-gray-700'}`}>
                <div className="flex items-center relative pl-0 pr-2">
                    {/* Left section */}
                    <div className="flex items-center gap-1 flex-shrink-0">
                        {isSmallScreen ? (
                            // Mobile: Show either hamburger or dashboard icon on left
                            !showUserManager && (  // Only show if not in waitlist view
                                <Button
                                    variant="transparent"
                                    onClick={showDashboard ? 
                                        () => setShowDashboard(false) : 
                                        () => setIsCollapsed(!isSidebarCollapsed)
                                    }
                                    icon={showDashboard ? <FontAwesomeIcon icon={Home} className="w-4 h-4" /> : <Menu size={16} />}
                                    title={showDashboard ? "Go to Workspace" : "Toggle sidebar"}
                                />
                            )
                        ) : (
                            // Desktop: Show hamburger menu on left when in workspace
                            !showDashboard && !showUserManager && (
                                <Button
                                    variant="transparent"
                                    onClick={() => setIsCollapsed(!isSidebarCollapsed)}
                                    icon={<Menu size={16} />}
                                    title="Toggle sidebar"
                                />
                            )
                        )}
                    </div>

                    {/* Center section */}
                    <div className={`flex-1 flex items-center justify-center gap-1 ${!isSmallScreen ? 'ml-24' : ''}`}>
                        {/* Show dashboard icon only when in workspace view or waitlist view */}
                        {(!showDashboard || showUserManager) && (
                            <button
                                onClick={() => {
                                    setShowDashboard(true);
                                    setShowUserManager(false);
                                }}
                                className="p-1.5 text-gray-200 hover:text-white rounded-lg hover:bg-gray-800/50"
                                title="Go to Dashboard"
                            >
                                <FontAwesomeIcon icon={Home} className="w-4 h-4" />
                            </button>
                        )}
                        <div className={`font-semibold text-white ${isSmallScreen ? 'text-xs' : 'text-sm'}`}>
                            {showUserManager ? 'User Management' : 
                             !showDashboard && projectName}
                        </div>
                   </div>

                    {/* Right section with tokens and user menu */}
                    <div className="flex items-center gap-3">
                        {/* Token display */}
                        {usage && (
                            <div className="relative" ref={tokenRef}>
                                <div className="hidden md:block">
                                    <TokenDisplay 
                                        onShowDetails={handleShowTokenDetails}
                                    />
                                </div>
                                <div className="md:hidden">
                                    <TokenDisplay 
                                        onShowDetails={handleShowTokenDetails}
                                    />
                                </div>
                            </div>
                        )}

                        {/* User menu */}
                        <div className="relative flex justify-end flex-shrink-0" ref={menuRef}>
                            <button
                                onClick={() => setShowLogout(!showLogout)}
                                className="p-1.5 flex items-center focus:outline-none hover:bg-gray-800/50 rounded-lg transition-colors duration-150"
                            >
                                {user?.picture ? (
                                    <img
                                        src={user.picture}
                                        alt={user.name}
                                        className="w-6 h-6 rounded-full"
                                    />
                                ) : (
                                    <User size={16} className="text-gray-200" />
                                )}
                            </button>
                            {showLogout && (
                                <div 
                                    className="absolute right-0 mt-2 py-2 w-48 bg-gray-800/95 backdrop-blur-sm rounded-lg shadow-xl"
                                >
                                    {isAdmin && (
                                        <>
                                            <button
                                                className="flex items-center px-4 py-2 w-full text-left text-sm text-gray-200 hover:bg-gray-700/50"
                                                onClick={() => {
                                                    setShowUserManager(true);
                                                    setShowLogout(false);
                                                }}
                                            >
                                                <Users size={16} className="mr-2" />
                                                Manage Users
                                            </button>
                                            <div className="my-1 border-t border-gray-700/50" />
                                        </>
                                    )}
                                    <button
                                        className="flex items-center px-4 py-2 w-full text-left text-sm text-gray-200 hover:bg-gray-700/50"
                                        onClick={() => {
                                            handleLogOut();
                                            setShowLogout(false);
                                        }}
                                    >
                                        <User size={16} className="mr-2" />
                                        Logout
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </header>
            
            {/* Add TokenUsageDetails modal */}
            {showTokenDetails && (
                <TokenUsageDetails
                    onClose={() => setShowTokenDetails(false)}
                />
            )}
        </>
    );
};

export default Header;