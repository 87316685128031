import { useState, useCallback, useRef, useEffect } from 'react';

const useVoiceInput = () => {
  const [isListening, setIsListening] = useState(false);
  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);
  const [error, setError] = useState(null);

  const getSupportedMimeType = () => {
    const audioTypes = [
      'audio/webm',
      'audio/ogg',
      'audio/mp4',
      'audio/wav'
    ];
    
    for (const type of audioTypes) {
      if (MediaRecorder.isTypeSupported(type)) {
        return type;
      }
    }
    throw new Error('No supported audio MIME type found');
  };

  const startListening = useCallback(async () => {
    try {
      setError(null);
      
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });

      const mimeType = getSupportedMimeType();

      mediaRecorder.current = new MediaRecorder(stream, { mimeType });

      audioChunks.current = [];

      mediaRecorder.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push(event.data);
        }
      };
      mediaRecorder.current.start(100);

      setIsListening(true);
    } catch (err) {
      setError(err.message);
      console.error('Error starting recording:', err);
    }
  }, []);

  const stopListening = useCallback(() => {
    return new Promise((resolve) => {
      if (!mediaRecorder.current || mediaRecorder.current.state === 'inactive') {
        resolve(null);
        return;
      }

      mediaRecorder.current.onstop = async () => {
        try {
          const audioBlob = new Blob(audioChunks.current, { type: mediaRecorder.current.mimeType });

          mediaRecorder.current.stream.getTracks().forEach(track => track.stop());
          
          setIsListening(false);
          resolve(audioBlob);
        } catch (err) {
          setError(err.message);
          console.error('Error stopping recording:', err);
          resolve(null);
        }
      };

      mediaRecorder.current.stop();
    });
  }, []);

  useEffect(() => {
    return () => {
      if (mediaRecorder.current && mediaRecorder.current.state !== 'inactive') {
        mediaRecorder.current.stream.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  return {
    isListening,
    startListening,
    stopListening,
    error
  };
};

export default useVoiceInput;
