import React, { useState, forwardRef, useEffect } from 'react';
import { HistoryIcon, AgentIcon, NewChatIcon, NewAgentIcon, ShareIcon, DatabaseIcon } from './ui/Icons';
import { X } from 'lucide-react';
import Agents from './Agents';
import Threads from './Threads';
import ErrorPopup from './ErrorPopup';
import ConfirmationDialog from './ConfirmationDialog';
import ShareProjectDialog from './ShareProjectDialog';
import Button from './ui/Button';
import DataSources from './DataSources';
import AgentEditDialog from './AgentEditDialog';

const ControlPanel = forwardRef(({
  threads,
  handleThreadSelect,
  loadMoreThreads,
  moreThreadsAvailable,
  deleteThread,
  selectedProjectId,
  selectedThreadId,
  isLoadingThreads,
  activeAgents,
  availableAgents,
  agentData,
  addAgentToConversation,
  updateAgent,
  deleteAgent,
  resetDefaultAgent,
  removeAgentFromConversation,
  createAgent,
  getAgentData,
  addAgentDataSource,
  removeAgentDataSource,
  refreshAgents,
  isLoadingAgents,
  renameThread,
  isRenamingThread,
  isAddingAgent,
  setIsAddingAgent,
  isCollapsed,
  setIsCollapsed,
  isSmallScreen,
  editingAgent,
  setEditingAgent,
  showShareDialog,
  setShowShareDialog,
  isCreatingAgent,
  isSharingProject,
  shareProject,
  createNewThread,
  isCreatingNewThread,
  agentVersions,
  getAgentWithVersions,
  handleGetConversationData,
  handleRemoveConversationDataSource,
  conversationData,
  models,
}, ref) => {
  const [error, setError] = useState(null);
  const [activeSection, setActiveSection] = useState('threads');
  const [confirmationDialog, setConfirmationDialog] = useState(null);
  const [isDataSourceDialogOpen, setIsDataSourceDialogOpen] = useState(false);

  useEffect(() => {
    if (activeSection === 'datasources' && selectedThreadId) {
      handleGetConversationData().catch(error => setError(error.message));
    }
  }, [selectedThreadId, activeSection, handleGetConversationData]);

  const handleSectionClick = async (section) => {
    if (section === 'datasources' && selectedThreadId) {
      // Fetch data sources when switching to datasources section
      try {
        await handleGetConversationData();
      } catch (error) {
        setError(error.message);
      }
    }

    if (isSmallScreen) {
      setActiveSection(section);
    } else {
      if (activeSection === section && !isCollapsed) {
        setIsCollapsed(true);
      } else {
        setActiveSection(section);
        setIsCollapsed(false);
      }
    }
  };

  const handleActionClick = async (action) => {
    try {
      switch (action) {
        case 'newAgent':
          setEditingAgent({});
          break;
        case 'shareProject':
          if (selectedProjectId) {
            setShowShareDialog(true);
          }
          break;
        default:
          break;
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleShareSubmit = async (email) => {
    try {
      if (selectedProjectId) {
        await shareProject(selectedProjectId, email);
        setShowShareDialog(false);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  // If collapsed and on mobile, don't render anything
  if (isSmallScreen && isCollapsed) {
    return null;
  }

  return (
    <aside 
      ref={ref} 
      className={`flex h-[calc(100vh-13px)] transition-all duration-300 ease-in-out ${
        isSmallScreen 
          ? 'w-full absolute z-50 left-0 right-0' 
          : isCollapsed ? 'w-14' : 'w-80'
      } bg-gray-800/90 shadow-xl rounded-r-lg mt-[13px]`}
    >
      {/* Icon sidebar - Update to match Workbench header/footer style */}
      {(!isSmallScreen || !isCollapsed) && (
        <div className="flex flex-col bg-gray-700/90 border-r border-gray-600/30 p-1 space-y-1 w-14">
          {/* Section Icons */}
          <Button
            variant="icon"
            onClick={() => handleSectionClick('threads')}
            className={`w-12 h-8 transition-all duration-200 ${
              activeSection === 'threads' && !isCollapsed
                ? 'bg-blue-500/90 shadow-lg shadow-blue-500/20' 
                : 'bg-gray-800/80 hover:bg-gray-600/50 hover:shadow-md'
            }`}
            icon={<HistoryIcon className="w-4 h-4" />}
            title="Conversations"
          />
 
          <Button
            variant="icon"
            onClick={() => handleSectionClick('agents')}
            className={`transition-all duration-200 ${
              activeSection === 'agents' && !isCollapsed
                ? 'bg-blue-500/90 shadow-lg shadow-blue-500/20' 
                : 'bg-gray-800/80 hover:bg-gray-600/50 hover:shadow-md'
            }`}
            icon={<AgentIcon />}
            title="Agents"
          />

          <Button
            variant="icon"
            onClick={() => handleActionClick('newAgent')}
            isLoading={isCreatingAgent}
            disabled={!selectedProjectId}
            className={`transition-all duration-200 ${
              !selectedProjectId 
                ? 'opacity-50 cursor-not-allowed bg-gray-800/50' 
                : 'bg-gray-800/80 hover:bg-gray-600/50 hover:shadow-md'
            }`}
            icon={<NewAgentIcon />}
            title="New Agent"
          />

          <Button
            variant="icon"
            onClick={() => handleActionClick('shareProject')}
            isLoading={isSharingProject}
            loadingText="Sharing..."
            disabled={!selectedProjectId}
            className={`transition-all duration-200 ${
              !selectedProjectId 
                ? 'opacity-50 cursor-not-allowed bg-gray-800/50' 
                : 'bg-gray-800/80 hover:bg-gray-600/50 hover:shadow-md'
            }`}
            icon={<ShareIcon />}
            title="Share Project"
          />

          <Button
            variant="icon"
            onClick={() => handleSectionClick('datasources')}
            className={`transition-all duration-200 ${
              activeSection === 'datasources' && !isCollapsed
                ? 'bg-blue-500/90 shadow-lg shadow-blue-500/20' 
                : 'bg-gray-800/80 hover:bg-gray-600/50 hover:shadow-md'
            }`}
            icon={<DatabaseIcon />}
            title="Data Sources"
          />
          
         <Button
            variant="icon"
            onClick={() => {
              setIsCollapsed(true);
              createNewThread();
            }}
            isLoading={isCreatingNewThread}
            disabled={!selectedProjectId}
            className={`transition-all duration-200 ${
              !selectedProjectId 
                ? 'opacity-50 cursor-not-allowed bg-gray-800/50' 
                : 'bg-gray-800/80 hover:bg-gray-600/50 hover:shadow-md'
            }`}
            icon={<NewChatIcon />}
            title="New Conversation"
          />

        </div>
      )}

      {/* Content area - Update to match Workbench content area */}
      {(!isCollapsed || isSmallScreen) && (
        <div className="flex-1 bg-gray-800/70 overflow-hidden flex flex-col transition-all duration-300 ease-in-out">
          {/* Header - Updated style */}
          <div className="bg-gray-800/90 bg-gradient-to-b from-gray-700/50 to-gray-800/90 h-7 flex justify-between items-center px-2 border-b border-gray-600/20 shadow-sm">
            <h2 className="text-[11px] uppercase tracking-wide font-medium text-gray-200">
              {activeSection === 'threads' ? 'Conversations' : 
               activeSection === 'agents' ? 'Agents' : 
               'Data Sources'}
            </h2>
            <button 
              onClick={() => setIsCollapsed(true)}
              className="text-gray-400 hover:text-gray-200 transition-colors p-0.5 rounded hover:bg-gray-700/50"
            >
              <X size={12} />
            </button>
          </div>

          {/* Content section */}
          <div className="flex-1 overflow-y-auto p-2">
            {activeSection === 'threads' ? (
              <Threads
                threads={threads}
                threadId={selectedThreadId}
                handleThreadSelect={handleThreadSelect}
                loadMoreThreads={loadMoreThreads}
                moreThreadsAvailable={moreThreadsAvailable}
                deleteThread={deleteThread}
                renameThread={renameThread}
                isLoadingThreads={isLoadingThreads}
                isRenamingThread={isRenamingThread}
                setIsCollapsed={setIsCollapsed}
                isSmallScreen={isSmallScreen}
              />
            ) : activeSection === 'agents' ? (
              <Agents
                activeAgents={activeAgents}
                availableAgents={availableAgents}
                agentData={agentData}
                getAgentWithVersions={getAgentWithVersions}
                addAgentToConversation={addAgentToConversation}
                updateAgent={updateAgent}
                deleteAgent={deleteAgent}
                resetDefaultAgent={resetDefaultAgent}
                removeAgentFromConversation={removeAgentFromConversation}
                createAgent={createAgent}
                getAgentData={getAgentData}
                addDataSource={addAgentDataSource}
                removeDataSource={removeAgentDataSource}
                refreshAgents={refreshAgents}
                isExpanded={true}
                isLoadingAgents={isLoadingAgents}
                isAddingAgent={isAddingAgent}
                setIsAddingAgent={setIsAddingAgent}
                models={models}
              />
            ) : (
              <DataSources
                dataSources={conversationData?.data_sources || []}
                onRemoveDataSource={handleRemoveConversationDataSource}
                isLoadingDataSources={isLoadingThreads}
                deletingDataSourceId={null}
                setIsCollapsed={setIsCollapsed}
                isSmallScreen={isSmallScreen}
              />
            )}
          </div>
        </div>
      )}

      {confirmationDialog && (
        <ConfirmationDialog
          title={confirmationDialog.title}
          message={confirmationDialog.message}
          onConfirm={confirmationDialog.onConfirm}
          onCancel={confirmationDialog.onCancel}
          confirmText={confirmationDialog.confirmText}
          cancelText={confirmationDialog.cancelText}
          isLoading={confirmationDialog.isLoading}
        />
      )}

      {editingAgent !== null && (
        <AgentEditDialog
          isOpen={true}
          onClose={() => setEditingAgent(null)}
          agent={editingAgent.id ? editingAgent : undefined}
          createAgent={createAgent}
          updateAgent={updateAgent}
          isNewAgent={!editingAgent.id}
          isLoading={isCreatingAgent}
          deleteAgent={deleteAgent}
          getAgentData={getAgentData}
          addDataSource={addAgentDataSource}
          removeDataSource={removeAgentDataSource}
          resetAgent={resetDefaultAgent}
          agentData={agentData}
          agentVersions={agentVersions}
          models={models}
        />
      )}

      {showShareDialog && (
        <ShareProjectDialog
          onShare={handleShareSubmit}
          onClose={() => setShowShareDialog(false)}
          isSharing={isSharingProject}
        />
      )}

      {error && (
        <ErrorPopup message={error} onClose={() => setError(null)} />
      )}
    </aside>
  );
});

export default ControlPanel;
